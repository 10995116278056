<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <div class="mb-16">
      <dougs-field [field]="legalPersonFields['legalForm']" formControlName="legalForm"></dougs-field>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['bylaws']?.isAvailable">
      <dougs-field
        formControlName="kbis"
        (uploadFile)="
          legalPersonComponentService.uploadFiles(partner, legalPersonFields['kbis'].input?.fileType, 'kbis', $event)
        "
        (deleteFile)="
          legalPersonComponentService.deleteFiles(
            partner,
            legalPersonFields['kbis']?.input?.fileType,
            'kbis',
            $event,
            legalPersonFields['kbis']?.input?.allowMultiple
          )
        "
        [field]="legalPersonFields['kbis']"
      ></dougs-field>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['kbis']?.isAvailable">
      <dougs-field
        formControlName="bylaws"
        (uploadFile)="
          legalPersonComponentService.uploadFiles(
            partner,
            legalPersonFields['bylaws'].input?.fileType,
            'bylaws',
            $event
          )
        "
        (deleteFile)="
          legalPersonComponentService.deleteFiles(
            partner,
            legalPersonFields['bylaws']?.input?.fileType,
            'bylaws',
            $event,
            legalPersonFields['bylaws']?.input?.allowMultiple
          )
        "
        [field]="legalPersonFields['bylaws']"
      ></dougs-field>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['siren']?.isAvailable">
      <dougs-field
        formControlName="siren"
        [field]="legalPersonFields['siren']"
        [actionButtonIcon]="
          legalPersonComponentService.isRefreshingCompanyFromSiren ? 'fal fa-sync fa-spin' : 'fal fa-sync'
        "
        [showActionButton]="legalPersonComponentService.shouldShowRefreshCompanyButton$ | async"
        (actionButtonClick)="
          legalPersonComponentService.refreshLegalPartnerFromSiren(partner);
          formPartnerService.legalPersonFormGroup.markAsPristine()
        "
      ></dougs-field>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['metadata.immatriculationId']?.isAvailable">
      <ng-container formGroupName="metadata">
        <dougs-field
          formControlName="immatriculationId"
          [field]="legalPersonFields['metadata.immatriculationId']"
        ></dougs-field>
      </ng-container>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['shareCapital']?.isAvailable">
      <dougs-field formControlName="shareCapital" [field]="legalPersonFields['shareCapital']"></dougs-field>
    </div>
    <div class="mb-16" *ngIf="legalPersonFields['headquarter.ape']?.isAvailable">
      <ng-container formGroupName="headquarter">
        <dougs-field formControlName="ape" [field]="legalPersonFields['headquarter.ape']"></dougs-field>
      </ng-container>
    </div>
    <div class="mb-16" formGroupName="metadata" *ngIf="legalPersonFields['metadata.country']?.isAvailable">
      <dougs-field [field]="legalPersonFields['metadata.country']" formControlName="country"></dougs-field>
    </div>
    <div formGroupName="headquarter" *ngIf="legalPersonFields['headquarter.apeDescription']?.isAvailable">
      <dougs-field
        formControlName="apeDescription"
        class="mb-16"
        [field]="legalPersonFields['headquarter.apeDescription']"
      ></dougs-field>
    </div>
  </ng-container>
</div>
