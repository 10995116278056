<form [formGroup]="formUpdateEmailService.formGroup" (ngSubmit)="onSubmit()">
  <div dougsModalTitle>
    <h6>Mettre à jour l'email de connexion</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-form-field>
      <label for="newEmail" dougsFormFieldLabel>Nouvel email</label>
      <input
        formControlName="email"
        type="email"
        id="newEmail"
        placeholder="Ex. : john.doe@dougs.fr"
        [required]="true"
        dougsFormFieldControl
      />
      <span dougsFormFieldError *ngIf="formService.isControlInvalid(formUpdateEmailService.formGroup.get('email'))">
        Le nouvel email est requis
      </span>
    </dougs-form-field>
    <dougs-form-field [noMargin]="!formUpdateEmailService.usePasswordChecks()">
      <label for="emailConfirmation" dougsFormFieldLabel>Confirmer l'email</label>
      <input
        formControlName="emailConfirmation"
        type="email"
        id="emailConfirmation"
        placeholder="Ex. : john.doe@dougs.fr"
        [required]="true"
        dougsFormFieldControl
      />
      <span
        dougsFormFieldError
        *ngIf="formService.isControlInvalid(formUpdateEmailService.formGroup.get('emailConfirmation'))"
      >
        La confirmation de l'email est requise
      </span>
      <span dougsFormFieldError *ngIf="formUpdateEmailService.formGroup.errors?.mustMatch">
        Les emails ne sont pas identiques
      </span>
    </dougs-form-field>
    <dougs-form-field [noMargin]="true" *ngIf="formUpdateEmailService.usePasswordChecks()">
      <label for="verifyPassword" dougsFormFieldLabel>Mot de passe de connexion</label>
      <input formControlName="password" type="password" id="verifyPassword" [required]="true" dougsFormFieldControl />
      <span dougsFormFieldError *ngIf="formService.isControlInvalid(formUpdateEmailService.formGroup.get('password'))">
        Le mot de passe est requis
      </span>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit" [disabled]="formUpdateEmailService.formGroup.invalid">Terminer</dougs-button>
  </div>
</form>
