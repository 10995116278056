<div class="form-layout align-center">
  <p class="small">
    <span class="bold">Informations personnelles</span>
    (identité, rôle dans l'entreprise)
    <ng-container *ngIf="completion">
      <dougs-pill type="warning" *ngIf="completion < 100"> Complété à {{ completion }}% </dougs-pill>
    </ng-container>
  </p>
  <dougs-button
    id="ownerMissingFields"
    color="secondary"
    (click)="partnerComponentService.openPartnerModal(activeUserPartner?.id, activeUserPartner?.companyId)"
  >
    Modifier
  </dougs-button>
</div>
