import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ButtonComponent,
  ConfirmationModalComponent,
  DividerComponent,
  ModalService,
  OverlayCloseEvent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-affiliate-form',
  templateUrl: './affiliate-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FieldComponent, DividerComponent, ButtonComponent],
})
export class AffiliateFormComponent {
  @Input() partner!: Partner;
  @Input() roleFields!: Fields;
  @Input() position!: PartnerPosition;

  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly formPartnerService: FormPartnerService,
    private readonly modalService: ModalService,
  ) {}

  resetForm(e: MouseEvent): void {
    e.stopPropagation();
    if (this.formPartnerService?.metadataAffiliateFormGroup?.dirty) {
      this.formPartnerService.resetMetadataRolesForm(this.partner, this.formPartnerService.metadataAffiliateFormGroup);
    }
    this.closeForm.emit();
  }

  async saveAndClose(e: MouseEvent): Promise<void> {
    e.stopPropagation();
    const isFormValid = await this.formPartnerService.submitForm(this.partner);
    if (isFormValid) {
      this.closeForm.emit();
    }
  }

  async deleteAffiliateRole(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer le rôle de ${this.position.label} ? Cette action est <b>définitive</b>.`,
            yesText: 'Oui, supprimer le rôle',
            noText: 'Non',
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      this.closeForm.emit();
      await this.formPartnerService.deleteRole(this.partner, this.position, 'affiliate');
    }
  }
}
