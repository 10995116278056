import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { DividerComponent } from '@dougs/ds';
import { Fields, RoleFields } from '@dougs/fields/dto';
import { Partner, PartnerPosition, PartnerPositionKey } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../forms/form-partner.service';
import { PartnerRoleContainerComponent } from './partner-role-container/partner-role-container.component';

@Component({
  selector: 'dougs-partner-roles',
  templateUrl: './partner-roles.component.html',
  styleUrls: ['./partner-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DividerComponent, NgFor, PartnerRoleContainerComponent],
})
export class PartnerRolesComponent {
  constructor(private readonly formPartnerService: FormPartnerService) {}

  private _positionToActivate!: PartnerPosition['key'] | null;
  @Input() set positionToActivate(positionToActivate: PartnerPosition['key'] | null) {
    this._positionToActivate = positionToActivate;

    if (this.positionToActivate && !this.hasInit && this.rolesFields) {
      this.updateActivePosition(this.positionToActivate);
      this.hasInit = true;
    }
  }

  get positionToActivate(): PartnerPositionKey | null {
    return this._positionToActivate;
  }

  @Input() company!: Company;
  @Input() naturalPersonFields!: Fields;
  private _rolesFields!: RoleFields;
  @Input()
  set rolesFields(roleFields: RoleFields) {
    this._rolesFields = roleFields;
    if (this.rolesFields) {
      this.formPartnerService.formatPartnerMetadataRoleForm(roleFields);
    }
  }

  get rolesFields(): RoleFields {
    return this._rolesFields;
  }

  @Input() partner!: Partner;

  private _partnerFields!: Fields;
  @Input()
  set partnerFields(partnerFields: Fields) {
    this._partnerFields = partnerFields;
    this.partnerPositions = this.sortPositions(partnerFields.positions?.input?.options as unknown as PartnerPosition[]);
  }

  get partnerFields(): Fields {
    return this._partnerFields;
  }

  public partnerPositions?: PartnerPosition[];
  public activePositionKey!: string | null;
  hasInit = false;

  private sortPositions(positions: PartnerPosition[]): PartnerPosition[] {
    return positions.sort((positionA, positionB) => {
      if (positionA.isActive && !positionB.isActive) {
        return -1;
      }
      if (positionB.isActive && !positionA.isActive) {
        return 1;
      }
      return positionA.label.localeCompare(positionB.label);
    });
  }

  trackByKey(index: number, item: PartnerPosition): string {
    return item.key;
  }

  updateActivePosition(activePositionKey: string | null): void {
    this.activePositionKey = activePositionKey;
  }
}
