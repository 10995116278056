<div class="modal-container">
  <div dougsModalTitle>
    <h6>Ajouter une personne</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent="large">
    <div class="content">
      <div class="natural-person" (click)="addPartner('natural')">
        <img src="images/illustrations/natural-person.png" />
        <div class="icon-container p-24">
          <i class="fal fa-plus mr-8"></i>
          <p class="bold">Ajouter une personne physique</p>
        </div>
      </div>
      <div class="legal-person" (click)="addPartner('legal')">
        <img src="images/illustrations/legal-person.png" />
        <div class="icon-container p-24">
          <i class="fal fa-plus mr-8"></i>
          <p class="bold">Ajouter une personne morale</p>
        </div>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button (click)="closeModal()" color="secondary">Annuler</dougs-button>
  </div>
</div>
