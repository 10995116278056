<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <ng-container formGroupName="metadata">
      <ng-container
        formGroupName="courtsAdministrationService"
        *ngIf="legalPersonFields['metadata.courtsAdministrationService.name']?.isAvailable"
      >
        <div class="mb-16">
          <div>
            <label dougsFormFieldLabel>Greffe compétent</label>
            <dougs-select
              maxHeight="300"
              bindValue="name"
              dougsFormFieldControl
              [ngModel]="formPartnerService.selectedCourtAdministration"
              (ngModelChange)="formPartnerService.updateCourtAdministrationService($event)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!legalPersonFields['metadata.courtsAdministrationService.name']?.isEditable"
              placeholder="-- Greffe compétent --"
            >
              <dougs-select-option
                *ngFor="let option of configBackService.courtAdministrationServices$ | async; trackBy: trackByName"
                [value]="option"
              >
                {{ option.name }}
              </dougs-select-option>
            </dougs-select>
          </div>
        </div>
        <div>
          <dougs-field
            formControlName="street"
            class="mb-16"
            [field]="legalPersonFields['metadata.courtsAdministrationService.street']"
          ></dougs-field>
        </div>
        <div>
          <div class="form-layout__two-columns-small-first mb-16">
            <dougs-field
              formControlName="zipcode"
              [field]="legalPersonFields['metadata.courtsAdministrationService.zipcode']"
            ></dougs-field>
            <dougs-field
              formControlName="city"
              [field]="legalPersonFields['metadata.courtsAdministrationService.city']"
            ></dougs-field>
          </div>
        </div>
        <div>
          <div class="mb-16">
            <dougs-field
              formControlName="phone"
              [field]="legalPersonFields['metadata.courtsAdministrationService.phone']"
            ></dougs-field>
          </div>
          <div class="mb-16">
            <dougs-field
              formControlName="webSite"
              [field]="legalPersonFields['metadata.courtsAdministrationService.webSite']"
            ></dougs-field>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
