import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, CheckboxComponent, LoaderComponent } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerComponentService } from '../services/partner-component.service';
import { VendorsComponentService } from '../services/vendors-component.service';
import { PartnerComponent } from './partner/partner.component';

@Component({
  selector: 'dougs-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [VendorsComponentService],
  imports: [
    CommonModule,
    ButtonComponent,
    NgFor,
    CheckboxComponent,
    FormsModule,
    NgIf,
    PartnerComponent,
    NgClass,
    LoaderComponent,
  ],
})
export class VendorsComponent {
  @Input() company!: Company;

  private _vendors!: Partner[];
  @Input()
  set vendors(vendors: Partner[]) {
    if (vendors) {
      this._vendors = vendors;
      this.vendorsComponentService.updateFilteredVendors(vendors);
    }
  }

  get vendors(): Partner[] {
    return this._vendors;
  }

  @ViewChild('vendorsContainer') vendorsContainerElementRef!: ElementRef;

  constructor(
    public readonly partnerComponentService: PartnerComponentService,
    public readonly vendorsComponentService: VendorsComponentService,
  ) {}

  trackById(index: number, partner: Partner): number {
    return partner.id;
  }

  trackByKey(index: number, item: { key: string; label: string }): string {
    return item.key;
  }

  async openAddPartnerModal(): Promise<void> {
    await this.partnerComponentService.openAddPartnerModal();
  }
}
