import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { LegalPersonComponentService } from '../../../../services/legal-person.component.service';

@Component({
  selector: 'dougs-legal-person-legal',
  templateUrl: './legal-person-legal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FieldComponent, NgIf, AsyncPipe],
})
export class LegalPersonLegalComponent {
  @Input() partner!: Partner;
  @Input() legalPersonFields!: Fields;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly legalPersonComponentService: LegalPersonComponentService,
  ) {}
}
