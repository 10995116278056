<div class="mt-24" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <div class="partner-settings-card__content p-24">
      <div>
        <h5 class="partner-settings-card__content__sticky">Entreprise</h5>
      </div>
      <dougs-legal-person-general
        [partner]="partner"
        [legalPersonFields]="legalPersonFields"
        [partnerFields]="partnerFields"
      ></dougs-legal-person-general>
    </div>
    <dougs-divider></dougs-divider>
    <div class="partner-settings-card__content p-24">
      <div>
        <h5 class="partner-settings-card__content__sticky">Siège social</h5>
      </div>
      <dougs-legal-person-headquarter
        [partner]="partner"
        [legalPersonFields]="legalPersonFields"
      ></dougs-legal-person-headquarter>
    </div>
    <dougs-divider></dougs-divider>
    <div class="partner-settings-card__content p-24">
      <div>
        <h5 class="partner-settings-card__content__sticky">Information juridique</h5>
      </div>
      <dougs-legal-person-legal
        [partner]="partner"
        [legalPersonFields]="legalPersonFields"
        [dougsDetectFormChanges]="formPartnerService.formGroupHasBeenTouched$ | async"
      ></dougs-legal-person-legal>
    </div>
    <dougs-divider></dougs-divider>
    <div class="partner-settings-card__content p-24">
      <div>
        <h5 class="partner-settings-card__content__sticky">Représentant légal</h5>
      </div>
      <dougs-legal-person-representative
        [partner]="partner"
        [legalPersonFields]="legalPersonFields"
      ></dougs-legal-person-representative>
    </div>
    <ng-container *ngIf="legalPersonFields['metadata.courtsAdministrationService']?.isAvailable">
      <dougs-divider></dougs-divider>
      <div class="partner-settings-card__content p-24">
        <div>
          <h5 class="partner-settings-card__content__sticky">Greffe</h5>
        </div>
        <dougs-legal-person-court-administration
          [partner]="partner"
          [legalPersonFields]="legalPersonFields"
        ></dougs-legal-person-court-administration>
      </div>
    </ng-container>
  </ng-container>
</div>
