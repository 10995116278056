<div class="form" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <div class="avatar">
      <label for="legal-avatar">
        <div class="avatar__edition-background"></div>
        <dougs-avatar size="xxlarge" [image]="partnerComponentService.getPartnerImageUrl(partner)"></dougs-avatar>
        <i class="fal fa-pencil color-white"></i>
        <input id="legal-avatar" type="file" accept="image/*" (change)="uploadAvatar($event)" />
        <image-cropper
          [imageFile]="imageFile"
          [maintainAspectRatio]="true"
          [resizeToWidth]="300"
          [resizeToHeight]="300"
          [aspectRatio]="1"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </label>
    </div>
    <div class="mb-16">
      <dougs-field [field]="legalPersonFields['legalName']" formControlName="legalName"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field [field]="legalPersonFields['brandName']" formControlName="brandName"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field [field]="legalPersonFields['activity']" formControlName="activity"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field [field]="legalPersonFields['website']" formControlName="website"></dougs-field>
    </div>
    <dougs-partner-position-field [partner]="partner" [partnerFields]="partnerFields"></dougs-partner-position-field>
  </ng-container>
</div>
