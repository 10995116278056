import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import {
  ControlFormFieldDirective,
  LabelFormFieldDirective,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { NaturalPersonComponentService } from '../../../../services/natural-person-component.service';
import { NaturalPersonTnsComponent } from '../natural-person-tns/natural-person-tns.component';

@Component({
  selector: 'dougs-natural-person-information',
  templateUrl: './natural-person-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FieldComponent,
    NgIf,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    PanelInfoComponent,
    NaturalPersonTnsComponent,
    AsyncPipe,
  ],
})
export class NaturalPersonInformationComponent {
  @Input() partner!: Partner;
  @Input() naturalPersonFields!: Fields;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly companyService: CompanyStateService,
    public readonly naturalPersonComponentService: NaturalPersonComponentService,
    public readonly configBackService: ConfigBackService,
  ) {}

  trackByValue(index: number, item: Country): string {
    return item.value;
  }
}
