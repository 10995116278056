import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { UserStateService } from '@dougs/user/shared';
import { PartnerComponentService } from '../../../services/partner-component.service';

type PartnerMenuItem = 'identity' | 'roles' | 'admin';

@Component({
  selector: 'dougs-partner-menu',
  templateUrl: './partner-menu.component.html',
  styleUrls: ['./partner-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, AsyncPipe],
})
export class PartnerMenuComponent {
  @Input() identitySectionElement!: ElementRef;
  @Input() rolesSectionElement!: ElementRef;
  @Input() adminSectionElement!: ElementRef;
  @Input() identitySectionActive!: boolean;
  @Input() rolesSectionActive!: boolean;
  @Input() adminSectionActive!: boolean;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly partnerComponentService: PartnerComponentService,
  ) {}

  activateMenu(partnerMenuItem: PartnerMenuItem) {
    switch (partnerMenuItem) {
      case 'identity':
        this.identitySectionElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'roles':
        this.rolesSectionElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'admin':
        this.adminSectionElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }
}
