<div *ngIf="roleFields" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="associate">
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.associate.shareCount']" formControlName="shareCount"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.associate.endDate']"
          [isAdmin]="true"
          formControlName="endDate"
        ></dougs-field>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="partner?.isAssociate">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer ce rôle ?
      <a class="color-error" href="#" (click)="deleteAssociateRole($event)">Supprimer</a>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button color="secondary mr-8" size="small" (click)="resetForm($event)">
      {{ formPartnerService.metadataAssociateFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      *ngIf="formPartnerService?.metadataAssociateFormGroup?.dirty"
      color="primary"
      size="small"
      (click)="saveAndClose($event)"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
