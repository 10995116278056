import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import { isPhone } from '@dougs/core/form';
import {
  AddressAutocompleteComponent,
  AddressComponent,
  AddressFieldsEnum,
  AvatarComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  ErrorFormFieldDirective,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { UserStateService } from '@dougs/user/shared';
import { ProfileIdProofCheckComponent } from '../../../../components/profile/profile-id-proof-check/profile-id-proof-check.component';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { IdCheckComponentService } from '../../../../services/id-check.component.service';
import { NaturalPersonComponentService } from '../../../../services/natural-person-component.service';
import { PartnerComponentService } from '../../../../services/partner-component.service';
import { UpdateEmailComponentService } from '../../../../services/update-email.component.service';
import { PartnerPositionFieldComponent } from '../../partner-position-field/partner-position-field.component';

@Component({
  selector: 'dougs-natural-person-contact',
  templateUrl: './natural-person-contact.component.html',
  styleUrls: ['./natural-person-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AvatarComponent,
    ImageCropperModule,
    FieldComponent,
    NgIf,
    ButtonComponent,
    PanelInfoComponent,
    ErrorFormFieldDirective,
    PartnerPositionFieldComponent,
    AsyncPipe,
    DividerComponent,
    ProfileIdProofCheckComponent,
    AddressAutocompleteComponent,
    AddressComponent,
    SelectComponent,
    SelectOptionComponent,
    ControlFormFieldDirective,
    NgForOf,
  ],
  providers: [IdCheckComponentService],
})
export class NaturalPersonContactComponent implements OnInit {
  protected readonly AddressFieldsEnum = AddressFieldsEnum;
  public isOwnerProfile = false;
  imageFile!: File | null;
  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.isOwnerProfile = this.getIsOwnerProfile();
  }

  get partner(): Partner {
    return this._partner;
  }

  @Input() naturalPersonFields!: Fields;
  @Input() partnerFields!: Fields;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly partnerComponentService: PartnerComponentService,
    public readonly formPartnerService: FormPartnerService,
    public readonly updateEmailComponentService: UpdateEmailComponentService,
    public readonly naturalPersonComponentService: NaturalPersonComponentService,
    public readonly companyStateService: CompanyStateService,
    public readonly partnerStateService: PartnerStateService,
    public readonly configBackService: ConfigBackService,
  ) {}

  ngOnInit(): void {
    this.formPartnerService.naturalPersonFormGroup.get('phone')?.setValidators(isPhone(false));
    this.formPartnerService.naturalPersonFormGroup.get('workPhone')?.setValidators(isPhone(false));
  }

  getIsOwnerProfile(): boolean {
    if (!this.partner || !this.partner.naturalPerson) {
      return false;
    }
    return this.userStateService.activeUser.profileId === this.partner.naturalPersonId;
  }

  uploadAvatar(e: Event): void {
    const fileList: FileList | null = (e.target as HTMLInputElement).files;
    const file: File | null | undefined = fileList?.item(0);
    if (fileList && file) {
      this.imageFile = file;
    }
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    if (event.blob && this.imageFile) {
      await this.formPartnerService.uploadAvatar(new File([event.blob], this.imageFile?.name), this.partner);
    }
  }
  trackByValue(index: number, item: Country): string {
    return item.value;
  }
}
