<div class="settings-card">
  <form
    *ngIf="formProfileService.formGroup"
    [formGroup]="formProfileService.formGroup"
    (ngSubmit)="formProfileService.submitForm()"
  >
    <div class="settings-card__content">
      <div>
        <div class="settings-card__sticky-column-title">
          <h5>Votre profil personnel</h5>
          <p class="subtitle mt-4">Les informations vous concernant</p>
          <ng-container *ngIf="userStateService.loggedInUser.isAccountantOrAdmin && session">
            <p class="mt-8 color-admin tiny">Inscrit le {{ activeUser.createdAt | date: 'dd MMMM yyyy' }}</p>
            <p class="color-admin tiny">
              Dernière session le {{ session.lastSeenAt | date: 'dd MMMM yyyy' }}
              <br />
              ({{ session.sessionCountInLast30Days }} sessions dans les 30 derniers jours)
            </p>
          </ng-container>
        </div>
      </div>
      <div class="settings-card__content__right">
        <ng-container *ngIf="formProfileService.formGroupChanges$ | async"></ng-container>
        <dougs-loader *ngIf="!ownerNaturalPersonFields || !partner"></dougs-loader>
        <ng-container *ngIf="ownerNaturalPersonFields && partner">
          <h5 class="mb-8">Identité et contact</h5>
          <ng-container formGroupName="profile">
            <div class="form-layout mb-16">
              <div class="form-layout__two-columns__avatar">
                <div class="avatar">
                  <label for="avatar">
                    <div class="avatar__edition-background"></div>
                    <dougs-avatar
                      size="xxlarge"
                      [image]="partnerComponentService.getPartnerImageUrl(partner)"
                    ></dougs-avatar>
                    <i class="fal fa-pencil color-white"></i>
                    <input id="avatar" type="file" accept="image/*" (change)="uploadAvatarFile($event)" />
                    <image-cropper
                      [imageFile]="imageFile"
                      [maintainAspectRatio]="true"
                      [resizeToWidth]="300"
                      [resizeToHeight]="300"
                      [aspectRatio]="1"
                      (imageCropped)="formProfileService.imageCropped($event, imageFile, activeUser)"
                    ></image-cropper>
                  </label>
                </div>
                <div class="form-layout__two-columns__avatar__name">
                  <dougs-field
                    formControlName="firstName"
                    [field]="ownerNaturalPersonFields['firstName']"
                    [canCopy]="true"
                  ></dougs-field>
                  <dougs-field
                    formControlName="lastName"
                    [field]="ownerNaturalPersonFields['lastName']"
                    [canCopy]="true"
                  ></dougs-field>
                </div>
              </div>
            </div>
            <div class="form-layout align-bottom mb-16">
              <dougs-field formControlName="email" [field]="ownerNaturalPersonFields['email']"></dougs-field>
              <dougs-button
                class="form-layout__no-grow"
                color="secondary"
                (click)="updateEmailComponentService.openUpdateEmailModal()"
              >
                Modifier
              </dougs-button>
            </div>
            <div class="form-layout mb-16" *ngIf="profileComponentService.shouldShowEmailConfirmation(partner) | async">
              <dougs-panel-info>
                Votre email n'est pas confirmé.
                <a href="#" (click)="$event.preventDefault(); partnerComponentService.sendEmailConfirmation()">
                  Renvoyer l'email de confirmation&nbsp;&rarr;
                </a>
              </dougs-panel-info>
            </div>
            <div class="form-layout align-bottom mb-16">
              <dougs-form-field [noMargin]="true">
                <label dougsFormFieldLabel>Mot de passe</label>
                <input value="******" type="text" dougsFormFieldControl [disabled]="true" />
              </dougs-form-field>
              <dougs-button color="secondary" (click)="profileComponentService.resetPassword()">Modifier</dougs-button>
            </div>
            <div class="form-layout mb-16">
              <dougs-field formControlName="phone" [field]="ownerNaturalPersonFields['phone']" [canCopy]="true">
                <span
                  dougsFormFieldError
                  *ngIf="formProfileService.formGroup.get('profile')?.get('phone')?.hasError('isPhone')"
                >
                  Le numéro de téléphone est invalide
                </span>
              </dougs-field>
            </div>
            <div class="form-layout mb-24" *ngIf="ownerNaturalPersonFields['workPhone']?.isAvailable">
              <dougs-field formControlName="workPhone" [field]="ownerNaturalPersonFields['workPhone']" [canCopy]="true">
                <span
                  dougsFormFieldError
                  *ngIf="formProfileService.formGroup.get('profile')?.get('workPhone')?.hasError('isPhone')"
                >
                  Le numéro de téléphone est invalide
                </span>
              </dougs-field>
            </div>
            <ng-container
              *ngIf="
                ownerNaturalPersonFields['identityProof']?.isAvailable &&
                !(companyStateService.activeCompany$ | async).isCreated
              "
            >
              <dougs-divider class="mb-16"></dougs-divider>
              <h5 class="mb-8">Pièce d’identité</h5>
              <p class="small">
                <span class="bold color-primary-700">Carte nationale d'identité</span>,
                <span class="bold color-primary-700">passeport</span>,
                <span class="bold color-primary-700">titre de séjour</span> ou
                <span class="bold color-primary-700">carte de résident</span>.
              </p>
              <p class="small mb-16">
                Les documents devront être importés en recto/verso (2 premières pages pour le passeport).
              </p>
            </ng-container>
            <div class="mb-16">
              <dougs-field
                formControlName="identityProof"
                (uploadFile)="
                  naturalPersonComponentService.uploadFiles(
                    partner,
                    ownerNaturalPersonFields['identityProof'].input.fileType,
                    'identityProof',
                    $event
                  )
                "
                (deleteFile)="
                  naturalPersonComponentService.deleteFiles(
                    partner,
                    ownerNaturalPersonFields['identityProof'].input.fileType,
                    'identityProof',
                    $event,
                    ownerNaturalPersonFields['identityProof'].input.allowMultiple
                  )
                "
                [field]="ownerNaturalPersonFields['identityProof']"
              ></dougs-field>
            </div>
          </ng-container>
          <div class="form-layout" *ngIf="profileComponentService.shouldShowPreferencesSection$ | async">
            <dougs-divider></dougs-divider>
          </div>
          <h5 class="mt-16 mb-8" *ngIf="profileComponentService.shouldShowPreferencesSection$ | async">Préférences</h5>
          <ng-container formGroupName="metadata">
            <div
              class="form-layout align-center mb-16"
              *ngIf="
                (profileComponentService.shouldShowPreferencesSection$ | async) &&
                ((userStateService.loggedInUser$ | async).isAccountantOrAdmin ||
                  (companyStateService.activeCompany$ | async).isCreated)
              "
            >
              <label dougsFormFieldLabel>Notifications par email des nouvelles opérations bancaires</label>
              <dougs-radio-group formControlName="subscribedToOperationNotifications" appearance="yesNo">
                <dougs-radio [value]="true">Oui</dougs-radio>
                <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
              </dougs-radio-group>
            </div>
            <div
              class="form-layout align-center mb-16"
              *ngIf="
                (profileComponentService.shouldShowPreferencesSection$ | async) &&
                (userStateService.activeUser$ | async).isAccountantOrAdmin
              "
            >
              <label dougsFormFieldLabel [isAdmin]="true">Afficher le menu en mode réduit</label>
              <dougs-radio-group [isAdmin]="true" formControlName="compactAppAside" appearance="yesNo">
                <dougs-radio [value]="true">Oui</dougs-radio>
                <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
              </dougs-radio-group>
            </div>
            <div
              class="form-layout align-center mb-16"
              *ngIf="profileComponentService.shouldShowPreferencesSection$ | async"
            >
              <label dougsFormFieldLabel>Apparence de l'application</label>
              <dougs-select
                class="select-theme"
                formControlName="darkMode"
                dougsFormFieldControl
                placeholder="Sélectionner l'apparence de l'application"
              >
                <dougs-select-option value="light">
                  <i class="fal fa-sun mr-8"></i>
                  Clair
                </dougs-select-option>
                <dougs-select-option value="dark">
                  <i class="fal fa-moon mr-8"></i>
                  Sombre
                </dougs-select-option>
                <dougs-select-option value="system">
                  <i class="fal fa-cloud-moon mr-8"></i>
                  Identique à celle du système
                </dougs-select-option>
              </dougs-select>
            </div>
          </ng-container>
          <div class="form-layout" *ngIf="profileComponentService.shouldShowProfileSection$ | async">
            <dougs-divider></dougs-divider>
          </div>
          <h5 class="mt-16 mb-8" *ngIf="profileComponentService.shouldShowProfileSection$ | async">Profil</h5>
          <dougs-profile-profile
            *ngIf="profileComponentService.shouldShowProfileSection$ | async"
            [completion]="completion"
            [partners]="partnerStateService.partners$ | async"
          ></dougs-profile-profile>
        </ng-container>
      </div>
    </div>
    <div class="settings-card__button-container py-16 px-32" *ngIf="formProfileService.formGroup.dirty">
      <dougs-button color="secondary" class="mr-24" (click)="formProfileService.cancelForm(partner, activeUser)">
        Annuler
      </dougs-button>
      <dougs-button type="submit">Enregistrer</dougs-button>
    </div>
  </form>
  <div
    *ngIf="
      (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
      (userStateService.loggedInUser$ | async)?.flags.includes('canUpdateAccountOwnerCompliance')
    "
  >
    <dougs-divider *ngIf="companyFields['metadata.accountOwnerCompliance'].isAvailable"></dougs-divider>
    <dougs-profile-owner-compliance
      [companyFields]="companyFields"
      [company]="company"
      [partner]="partner"
    ></dougs-profile-owner-compliance>
  </div>
</div>
