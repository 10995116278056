import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { UserStateService } from '@dougs/user/shared';
import { IdCheckComponentService } from '../../../services/id-check.component.service';

@Component({
  selector: 'dougs-profile-id-proof-check',
  templateUrl: './profile-id-proof-check.component.html',
  styleUrls: ['./profile-id-proof-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [IdCheckComponentService],
  imports: [FieldComponent, ReactiveFormsModule, ButtonComponent, NgIf, AsyncPipe, NgClass],
})
export class ProfileIdProofCheckComponent {
  private _partner!: Partner;
  disabledIdentityCheckButton = false;
  checkStatus: string | undefined = '';
  message: string | undefined = '';

  @Input()
  set partner(partner: Partner) {
    this.disabledIdentityCheckButton = this.idCheckService.getDisabledIdentityCheckButton(partner);
    const idProofCheckResult: { message: string; checkStatus: string } | undefined =
      this.idCheckService.generateIdProofCheckMessage(partner);
    this.checkStatus = idProofCheckResult?.checkStatus;
    this.message = idProofCheckResult?.message;
    this._partner = partner;
  }

  get partner(): Partner {
    return this._partner;
  }
  @Input() naturalPersonFields!: Fields;
  @Input() formGroup!: UntypedFormGroup;

  constructor(
    public readonly companyStateService: CompanyStateService,
    public readonly partnerStateService: PartnerStateService,
    public readonly userStateService: UserStateService,
    public readonly idCheckService: IdCheckComponentService,
  ) {}
}
