import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  DatepickerComponent,
  DatepickerDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  RadioComponent,
  RadioGroupComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner } from '@dougs/partners/dto';
import { ProfileMetadata, Tns } from '@dougs/user/dto';
import { FormPartnerService } from '../../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-natural-person-tns-form',
  templateUrl: './natural-person-tns-form.component.html',
  styleUrls: ['./natural-person-tns-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    RadioGroupComponent,
    RadioComponent,
    ControlFormFieldDirective,
    DividerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    DatepickerDirective,
    SuffixFormFieldDirective,
    DatepickerComponent,
    CheckboxComponent,
    ButtonComponent,
  ],
})
export class NaturalPersonTnsFormComponent implements OnInit, OnDestroy {
  insurancePlanSubscription?: Subscription;
  simultaneouselySubscription?: Subscription;
  @Input() partner!: Partner;
  @Input() naturalPersonFields!: Fields;

  @Output() closeTnsForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly formPartnerService: FormPartnerService,
    private readonly fieldsStateService: FieldsStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.setupPartnerTns();
    await this.fieldsStateService.refreshPartnerFields(this.partner);
    this.setupAndListenInsurancePlan();
    this.setupAndListenSimultaneousely();
  }

  ngOnDestroy(): void {
    this.insurancePlanSubscription?.unsubscribe();
    this.simultaneouselySubscription?.unsubscribe();
  }

  async saveAndClose(): Promise<void> {
    await this.formPartnerService.submitForm(this.partner);
    this.closeTnsForm.emit();
  }

  resetForm(): void {
    if (this.formPartnerService?.tnsFormGroup?.dirty) {
      this.formPartnerService.resetTnsForm(this.partner);
    }
    this.closeTnsForm.emit();
  }

  private setupPartnerTns(): void {
    if (!this.partner.naturalPerson?.metadata) {
      this.partner.naturalPerson.metadata = {} as ProfileMetadata;
    }
    if (!this.partner.naturalPerson.metadata?.tns) {
      this.partner.naturalPerson.metadata.tns = {} as Tns;
    }
  }

  private setupAndListenInsurancePlan(): void {
    if (this.formPartnerService.tnsFormGroup?.get('insurancePlan')?.value !== 'other') {
      this.formPartnerService.tnsFormGroup.get('insurancePlanOtherValue')?.disable({ emitEvent: false });
    }
    this.insurancePlanSubscription = this.formPartnerService.tnsFormGroup
      .get('insurancePlan')
      ?.valueChanges.pipe(
        map((insurancePlan) => {
          if (insurancePlan === 'other') {
            this.formPartnerService.tnsFormGroup.get('insurancePlanOtherValue')?.enable({ emitEvent: false });
          } else {
            this.formPartnerService.tnsFormGroup.get('insurancePlanOtherValue')?.disable({ emitEvent: false });
          }
        }),
      )
      .subscribe();
  }

  private setupAndListenSimultaneousely(): void {
    if (this.formPartnerService.tnsFormGroup?.get('simultaneousely')?.value !== 'other') {
      this.formPartnerService.tnsFormGroup.get('simultaneouselyOtherValue')?.disable({ emitEvent: false });
    }
    this.simultaneouselySubscription = this.formPartnerService.tnsFormGroup
      .get('simultaneousely')
      ?.valueChanges.pipe(
        map((simultaneousely) => {
          if (simultaneousely === 'other') {
            this.formPartnerService.tnsFormGroup.get('simultaneouselyOtherValue')?.enable({ emitEvent: false });
          } else {
            this.formPartnerService.tnsFormGroup.get('simultaneouselyOtherValue')?.disable({ emitEvent: false });
          }
        }),
      )
      .subscribe();
  }

  showSpouseNote(): boolean {
    return (
      this.formPartnerService.tnsFormGroup.get('spouseIsCoveredByPartner')?.value ||
      this.formPartnerService.tnsFormGroup.get('isSpouseCovered')?.value
    );
  }
}
