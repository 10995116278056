<div class="parents-form-container" [formGroup]="formPartnerService.metadataParentsFormGroup">
  <h5 class="mb-24">Parents</h5>
  <div class="father mb-16" *ngIf="partnerFields['metadata.parents.father.lastName']?.isAvailable">
    <fieldset>
      <legend>Parent 1</legend>
      <div class="double-form-row mb-16" formGroupName="father">
        <dougs-field
          [field]="partnerFields['metadata.parents.father.firstName']"
          formControlName="firstName"
        ></dougs-field>
        <dougs-field
          [field]="partnerFields['metadata.parents.father.lastName']"
          formControlName="lastName"
        ></dougs-field>
      </div>
      <div
        class="mb-16"
        formGroupName="father"
        *ngIf="partnerFields['metadata.parents.father.maidenName']?.isAvailable"
      >
        <dougs-field
          [field]="partnerFields['metadata.parents.father.maidenName']"
          formControlName="maidenName"
        ></dougs-field>
      </div>
      <dougs-field
        [field]="partnerFields['metadata.parents.noFather']"
        [showLabel]="false"
        formControlName="noFather"
      ></dougs-field>
    </fieldset>
  </div>
  <div class="mother" *ngIf="partnerFields['metadata.parents.mother.lastName']?.isAvailable">
    <fieldset>
      <legend>Parent 2</legend>
      <div class="double-form-row mb-16" formGroupName="mother">
        <dougs-field
          [field]="partnerFields['metadata.parents.mother.firstName']"
          formControlName="firstName"
        ></dougs-field>
        <dougs-field
          [field]="partnerFields['metadata.parents.mother.lastName']"
          formControlName="lastName"
        ></dougs-field>
      </div>
      <div
        class="mb-16"
        formGroupName="mother"
        *ngIf="partnerFields['metadata.parents.mother.maidenName']?.isAvailable"
      >
        <dougs-field
          [field]="partnerFields['metadata.parents.mother.maidenName']"
          formControlName="maidenName"
        ></dougs-field>
      </div>
      <dougs-field
        [field]="partnerFields['metadata.parents.noMother']"
        [showLabel]="false"
        formControlName="noMother"
      ></dougs-field>
    </fieldset>
  </div>
  <ng-container *ngIf="partner?.metadata?.parents">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer les parents ?
      <span class="color-error pointer" (click)="deleteParents($event)">Supprimer</span>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button color="secondary mr-8" size="small" (click)="resetForm($event)">
      {{ formPartnerService.metadataParentsFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      *ngIf="formPartnerService?.metadataParentsFormGroup?.dirty"
      color="primary"
      size="small"
      (click)="saveAndClose($event)"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
