import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ButtonComponent,
  ConfirmationModalComponent,
  LabelFormFieldDirective,
  ModalService,
  OverlayCloseEvent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { NaturalPersonTnsFormComponent } from './natural-person-tns-form/natural-person-tns-form.component';

@Component({
  selector: 'dougs-natural-person-tns',
  templateUrl: './natural-person-tns.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LabelFormFieldDirective, ButtonComponent, NgClass, NgIf, NaturalPersonTnsFormComponent],
})
export class NaturalPersonTnsComponent {
  @Input() partner!: Partner;
  @Input() naturalPersonFields!: Fields;

  constructor(
    private readonly modalService: ModalService,
    private readonly formPartnerService: FormPartnerService,
  ) {}

  public showTnsForm = false;

  openTnsForm(): void {
    this.showTnsForm = true;
  }

  closeTnsForm(): void {
    this.showTnsForm = false;
  }

  async deleteTns(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer le formulaire TNS&nbsp;? Cette action est <b>définitive</b>.`,
            yesText: 'Oui, supprimer le formulaire TNS',
            noText: 'Non',
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      this.showTnsForm = false;
      await this.formPartnerService.deleteTns(this.partner);
    }
  }
}
