import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, SelectComponent, SelectOptionComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormProfileCompanyService } from '../../../forms/form-profile-company.service';

@Component({
  selector: 'dougs-profile-owner-compliance',
  templateUrl: './profile-owner-compliance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    SelectComponent,
    SelectOptionComponent,
    FieldComponent,
    AsyncPipe,
    ButtonComponent,
    NgIf,
    ReactiveFormsModule,
  ],
  providers: [FormProfileCompanyService],
})
export class ProfileOwnerComplianceComponent {
  public activeUserPartnerRolesAsString?: string;
  @Input()
  set partner(partner: Partner) {
    if (partner) {
      this.activeUserPartnerRolesAsString = this.formProfileCompanyService.setPartnerRolesAsString(partner);
    }
  }

  private _company!: Company;

  @Input()
  set company(company) {
    this._company = company;
    if (this.company) {
      this.formProfileCompanyService.populateForm(this.company);
    }
  }

  get company(): Company {
    return this._company;
  }

  private _companyFields!: Fields;
  @Input()
  set companyFields(companyFields) {
    this._companyFields = companyFields;
    if (this.companyFields) {
      this.formProfileCompanyService.formatForm(this.companyFields);
    }
  }

  get companyFields(): Fields {
    return this._companyFields;
  }

  constructor(public formProfileCompanyService: FormProfileCompanyService) {}
}
