<div class="form-layout">
  <div
    (click)="partnerComponentService.openPartnerModal(partner.id, company.id, partner.isVendor)"
    class="partner-line px-8 py-16"
  >
    <div class="partner-line__informations">
      <dougs-avatar [image]="partnerImageUrl" class="mr-16" size="large"></dougs-avatar>
      <div>
        <h5 *ngIf="partner?.fullName">{{ partner.fullName }}</h5>
        <p *ngIf="!partner?.fullName" class="bold">Non renseigné</p>
        <div *ngIf="partner?.position" class="position">{{ partner.position }}</div>
      </div>
    </div>
    <div class="partner-line__positions">
      <dougs-pill type="warning" *ngIf="showCompletionPill">
        Complété à {{ company.completion.models.partners[partner.id].percent }}%
      </dougs-pill>
      <dougs-pill
        *ngFor="let position of partnerActivePositions; trackBy: trackByLabel"
        [dougsTooltipDisable]="position.isStarted && !position.isEnded"
        [dougsTooltip]="
          position.isEnded
            ? 'Terminé le ' + (position.endDate | date: 'dd/MM/yyyy')
            : 'Commence le ' + (position.startDate | date: 'dd/MM/yyyy')
        "
        [type]="position.type"
        class="mr-4 p-4"
      >
        <span [ngStyle]="{ 'text-decoration': position.isEnded || !position.isStarted ? 'line-through' : 'none' }">{{
          position.label
        }}</span>
      </dougs-pill>
    </div>
  </div>
</div>
<dougs-panel-info
  class="partner-link-panel-info mb-16"
  type="admin"
  *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin && partner.linkedCompany"
>
  <i class="fal fa-info-circle mr-8"></i>
  <p class="small">
    Ce dossier est géré par Dougs.
    <a rel="noopener" [routerLink]="partner.linkedCompany.id | replaceActiveCompanyIdInUrl | async" target="_blank"
      >Voir le dossier</a
    >
  </p>
</dougs-panel-info>
<div class="form-layout">
  <dougs-divider></dougs-divider>
</div>
