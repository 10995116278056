import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import {
  AddressAutocompleteComponent,
  ButtonComponent,
  ConfirmationModalComponent,
  ControlFormFieldDirective,
  DividerComponent,
  LabelFormFieldDirective,
  ModalService,
  OverlayCloseEvent,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-natural-person-spouse-form',
  templateUrl: './natural-person-spouse-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FieldComponent,
    DividerComponent,
    NgIf,
    PanelInfoComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    ButtonComponent,
    AsyncPipe,
    AddressAutocompleteComponent,
  ],
})
export class NaturalPersonSpouseFormComponent {
  @Input() partner!: Partner;
  @Input() naturalPersonFields!: Fields;

  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly modalService: ModalService,
    public readonly formPartnerService: FormPartnerService,
    public readonly configBackService: ConfigBackService,
  ) {}

  resetForm(e: MouseEvent): void {
    e.stopPropagation();
    if (this.formPartnerService?.naturalPersonSpouseFormGroup?.dirty) {
      this.formPartnerService.resetNaturalPersonSpouseForm(this.partner);
    }
    this.closeForm.emit();
  }

  async saveAndClose(e: MouseEvent): Promise<void> {
    e.stopPropagation();
    const isFormValid = await this.formPartnerService.submitForm(this.partner);
    if (isFormValid) {
      this.closeForm.emit();
    }
  }

  trackByValue(index: number, item: Country): string {
    return item.value;
  }

  async deleteSpouse(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer le conjoint(e) ? Cette action est <b>définitive</b>.`,
            yesText: 'Oui, supprimer le conjoint(e)',
            noText: 'Non',
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      this.closeForm.emit();
      await this.formPartnerService.deleteSpouse(this.partner);
    }
  }
}
