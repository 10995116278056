import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ButtonComponent,
  ConfirmationModalComponent,
  DividerComponent,
  ModalService,
  OverlayCloseEvent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../../../forms/form-partner.service';
import { NaturalPersonComponentService } from '../../../../../../services/natural-person-component.service';
import { PartnerComponentService } from '../../../../../../services/partner-component.service';
import { EmployeeFormContractsComponent } from './employee-form-contracts/employee-form-contracts.component';
import { EmployeeFormWorkdaysComponent } from './employee-form-workdays/employee-form-workdays.component';
import { EmployeeFormWorkplaceComponent } from './employee-form-workplace/employee-form-workplace.component';
import { EmployeePayslipsComponent } from './employee-payslips/employee-payslips.component';

@Component({
  selector: 'dougs-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    EmployeePayslipsComponent,
    FieldComponent,
    EmployeeFormWorkplaceComponent,
    EmployeeFormContractsComponent,
    DividerComponent,
    EmployeeFormWorkdaysComponent,
    ButtonComponent,
  ],
})
export class EmployeeFormComponent {
  public showEmployeeInformations = false;

  private _roleFields!: Fields;
  private _naturalPersonFields!: Fields;

  @Input() partner!: Partner;

  @Input()
  set roleFields(roleFields: Fields) {
    this._roleFields = roleFields;
    if (roleFields) {
      this.updateShowEmployeeInformations(roleFields);
    }
  }

  get roleFields(): Fields {
    return this._roleFields;
  }

  @Input() position!: PartnerPosition;

  @Input()
  set naturalPersonFields(naturalPersonFields: Fields) {
    this._naturalPersonFields = naturalPersonFields;
    if (naturalPersonFields) {
      this.updateShowEmployeeInformations(naturalPersonFields);
    }
  }

  get naturalPersonFields(): Fields {
    return this._naturalPersonFields;
  }

  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly formPartnerService: FormPartnerService,
    private readonly modalService: ModalService,
    public readonly partnerComponentService: PartnerComponentService,
    public readonly naturalPersonComponentService: NaturalPersonComponentService,
  ) {}

  resetForm(e: MouseEvent): void {
    e.stopPropagation();
    if (this.formPartnerService?.metadataEmployeeSocialFormGroup?.dirty) {
      this.formPartnerService.resetMetadataRolesForm(
        this.partner,
        this.formPartnerService.metadataEmployeeSocialFormGroup,
      );
    }
    this.closeForm.emit();
  }

  async saveAndClose(e: MouseEvent): Promise<void> {
    e.stopPropagation();
    const isFormValid = await this.formPartnerService.submitForm(this.partner);
    if (isFormValid) {
      this.closeForm.emit();
    }
  }

  async deleteEmployeeRole(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer le rôle de ${this.position.label} ? Cette action est <b>définitive</b>.`,
            yesText: 'Oui, supprimer le rôle',
            noText: 'Non',
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      this.closeForm.emit();
      await this.formPartnerService.deleteRole(this.partner, this.position, 'employee');
    }
  }

  private updateShowEmployeeInformations(roleFields: Fields): void {
    const hasFieldsToDisplay: boolean =
      roleFields['metadata.employee.social.familyPersonCount']?.isAvailable ||
      roleFields['sejournProof']?.isAvailable ||
      roleFields['workLicenceProof']?.isAvailable ||
      roleFields['socialSecurityProof']?.isAvailable;
    if (hasFieldsToDisplay) {
      this.showEmployeeInformations = true;
    }
  }
}
