import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import {
  AddressAutocompleteComponent,
  AddressFieldsEnum,
  ControlFormFieldDirective,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-natural-person-birth',
  templateUrl: './natural-person-birth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FieldComponent,
    NgIf,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    AsyncPipe,
    AddressAutocompleteComponent,
  ],
})
export class NaturalPersonBirthComponent {
  protected readonly addressFieldsToDisplay: AddressFieldsEnum[] = [
    AddressFieldsEnum.CITY,
    AddressFieldsEnum.ZIPCODE,
    AddressFieldsEnum.COUNTRY,
  ];
  @Input() partner!: Partner;
  @Input() naturalPersonFields!: Fields;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly configBackService: ConfigBackService,
  ) {}

  trackByValue(index: number, item: Country): string {
    return item.value;
  }
}
