<div class="settings-menu mb-16 pt-16">
  <span class="pb-8" (click)="activateMenu('identity')" [ngClass]="{ 'is-active': identitySectionActive }">
    Identité
  </span>
  <span
    class="pb-8"
    *ngIf="partnerComponentService.shouldShowRolesSection$ | async"
    (click)="activateMenu('roles')"
    [ngClass]="{ 'is-active': rolesSectionActive }"
  >
    Rôles
  </span>
  <span
    (click)="activateMenu('admin')"
    [ngClass]="{
      'is-active': adminSectionActive,
      'is-admin': (userStateService.loggedInUser$ | async).isAccountantOrAdmin
    }"
    class="pb-8"
    *ngIf="partnerComponentService.shouldShowAdminSection$ | async"
  >
    Admin
  </span>
</div>
