import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { DetectFormChangesDirective, DividerComponent, PanelInfoComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormPartnerService } from '../../../forms/form-partner.service';
import { PartnerComponentService } from '../../../services/partner-component.service';
import { NaturalPersonBirthComponent } from './natural-person-birth/natural-person-birth.component';
import { NaturalPersonContactComponent } from './natural-person-contact/natural-person-contact.component';
import { NaturalPersonInformationComponent } from './natural-person-information/natural-person-information.component';
import { NaturalPersonParentsComponent } from './natural-person-parents/natural-person-parents.component';
import { NaturalPersonSpouseComponent } from './natural-person-spouse/natural-person-spouse.component';

@Component({
  selector: 'dougs-natural-person',
  templateUrl: './natural-person.component.html',
  styleUrls: ['./natural-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NaturalPersonContactComponent,
    DetectFormChangesDirective,
    NgIf,
    DividerComponent,
    NaturalPersonBirthComponent,
    NaturalPersonInformationComponent,
    PanelInfoComponent,
    NaturalPersonParentsComponent,
    FieldComponent,
    NaturalPersonSpouseComponent,
    AsyncPipe,
  ],
})
export class NaturalPersonComponent {
  @Input() partner!: Partner;

  private _naturalPersonFields!: Fields;
  @Input()
  set naturalPersonFields(naturalPersonFields: Fields) {
    this._naturalPersonFields = naturalPersonFields;
    if (this.naturalPersonFields) {
      this.shouldShowBirthSettings();
      this.shouldShowFamilySettings();
      this.formPartnerService.formatNaturalPersonForm(this.naturalPersonFields);
    }
  }

  get naturalPersonFields(): Fields {
    return this._naturalPersonFields;
  }

  private _partnerFields!: Fields;
  @Input()
  set partnerFields(partnerFields: Fields) {
    this._partnerFields = partnerFields;
    if (this.partnerFields) {
      this.shouldShowFamilySettings();
      this.formPartnerService.formatPartnerForm(this.partnerFields);
    }
  }

  get partnerFields(): Fields {
    return this._partnerFields;
  }

  public showBirthSettings = false;
  public showFamilySettings = false;

  @Input() company!: Company;

  constructor(
    public readonly partnerComponentService: PartnerComponentService,
    public readonly formPartnerService: FormPartnerService,
    public readonly userStateService: UserStateService,
  ) {}

  shouldShowBirthSettings(): void {
    if (this.naturalPersonFields) {
      this.showBirthSettings =
        this.naturalPersonFields['maidenName']?.isAvailable ||
        this.naturalPersonFields['birthDate']?.isAvailable ||
        this.naturalPersonFields['birthZipcode']?.isAvailable ||
        this.naturalPersonFields['birthCity']?.isAvailable ||
        this.naturalPersonFields['birthCountry']?.isAvailable;
    }
  }

  shouldShowFamilySettings(): void {
    if (this.naturalPersonFields && this.partnerFields) {
      this.showFamilySettings =
        this.naturalPersonFields['spouse']?.isAvailable || this.partnerFields['metadata.parents']?.isAvailable;
    }
  }
}
