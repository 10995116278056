<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <ng-container *ngIf="formPartnerService.legalPersonHeadquarterFormGroup" formGroupName="headquarter">
      <div class="mb-16" *ngIf="legalPersonFields['headquarter.street']?.isAvailable">
        <dougs-field
          [useAddressAutocomplete]="true"
          (selectAddress)="formPartnerService.onSelectHeadquarterAddress($event)"
          formControlName="street"
          [field]="legalPersonFields['headquarter.street']"
        ></dougs-field>
      </div>
      <div
        class="mb-16"
        *ngIf="
          legalPersonFields['headquarter.city']?.isAvailable || legalPersonFields['headquarter.zipcode']?.isAvailable
        "
      >
        <div class="form-layout__two-columns-small-first">
          <dougs-field formControlName="zipcode" [field]="legalPersonFields['headquarter.zipcode']"></dougs-field>
          <dougs-field formControlName="city" [field]="legalPersonFields['headquarter.city']"></dougs-field>
        </div>
      </div>
      <div class="mb-16" *ngIf="legalPersonFields['headquarter.establishmentOwnerId']?.isAvailable">
        <dougs-field
          formControlName="establishmentOwnerId"
          [field]="legalPersonFields['headquarter.establishmentOwnerId']"
        ></dougs-field>
      </div>
    </ng-container>
    <div class="mb-16" *ngIf="legalPersonFields['registrationDate']?.isAvailable">
      <dougs-field formControlName="registrationDate" [field]="legalPersonFields['registrationDate']"></dougs-field>
    </div>
    <ng-container formGroupName="metadata">
      <div class="mb-16" *ngIf="legalPersonFields['metadata.isDomiciliated']?.isAvailable">
        <dougs-field
          formControlName="isDomiciliated"
          [field]="legalPersonFields['metadata.isDomiciliated']"
        ></dougs-field>
      </div>
      <div *ngIf="legalPersonFields['metadata.domiciliatorSiren']?.isAvailable">
        <dougs-field
          formControlName="domiciliatorSiren"
          class="mb-16"
          [field]="legalPersonFields['metadata.domiciliatorSiren']"
        ></dougs-field>
      </div>
      <div *ngIf="legalPersonFields['metadata.domiciliatorLegalName']?.isAvailable">
        <dougs-field
          formControlName="domiciliatorLegalName"
          class="mb-16"
          [field]="legalPersonFields['metadata.domiciliatorLegalName']"
        ></dougs-field>
      </div>
    </ng-container>
  </ng-container>
</div>
