import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { FormUpdateEmailService } from '../../forms/form-update-email.service';

@Component({
  selector: 'dougs-update-email-modal',
  templateUrl: './update-email-modal.component.html',
  styleUrls: ['./update-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormUpdateEmailService],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    NgIf,
    ErrorFormFieldDirective,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class UpdateEmailModalComponent {
  constructor(
    public formUpdateEmailService: FormUpdateEmailService,
    public formService: FormService,
    private readonly modalRef: ModalRef,
  ) {}

  async onSubmit(): Promise<void> {
    await this.formUpdateEmailService.submitForm();
    this.modalRef.close();
  }
}
