<div [formGroup]="formPartnerService.formGroup" *ngIf="roleFields">
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <ng-container
          formGroupName="monday"
          *ngIf="roleFields['metadata.employee.social.monday.morningStartAt']?.isAvailable"
        >
          <h6>Lundi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.monday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.monday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.monday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.monday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="tuesday"
          *ngIf="roleFields['metadata.employee.social.tuesday.morningStartAt']?.isAvailable"
        >
          <h6>Mardi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.tuesday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.tuesday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.tuesday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.tuesday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="wednesday"
          *ngIf="roleFields['metadata.employee.social.wednesday.morningStartAt']?.isAvailable"
        >
          <h6>Mercredi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.wednesday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.wednesday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.wednesday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.wednesday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="thursday"
          *ngIf="roleFields['metadata.employee.social.thursday.morningStartAt']?.isAvailable"
        >
          <h6>Jeudi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.thursday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.thursday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.thursday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.thursday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="friday"
          *ngIf="roleFields['metadata.employee.social.friday.morningStartAt']?.isAvailable"
        >
          <h6>Vendredi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.friday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.friday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.friday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.friday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="saturday"
          *ngIf="roleFields['metadata.employee.social.saturday.morningStartAt']?.isAvailable"
        >
          <h6>Samedi</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.saturday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.saturday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.saturday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.saturday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
        <ng-container
          formGroupName="sunday"
          *ngIf="roleFields['metadata.employee.social.sunday.morningStartAt']?.isAvailable"
        >
          <h6>Dimanche</h6>
          <div class="mb-16 double-form-row">
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.sunday.morningStartAt']"
              formControlName="morningStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.sunday.morningEndAt']"
              formControlName="morningEndAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.sunday.afternoonStartAt']"
              formControlName="afternoonStartAt"
            ></dougs-field>
            <dougs-field
              [showLabel]="false"
              [field]="roleFields['metadata.employee.social.sunday.afternoonEndAt']"
              formControlName="afternoonEndAt"
            ></dougs-field>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
