<div (click)="openSpouseForm()" class="spouse-container py-16 pr-16 pl-8" [ngClass]="{ active: showSpouseForm }">
  <div class="spouse-container__content">
    <div class="spouse-icon">
      <div class="spouse-icon__content">
        <i class="fal fa-rings-wedding"></i>
      </div>
    </div>
    <div *ngIf="!showSpouseForm">
      <h5>Conjoint(e)</h5>
      <p *ngIf="showSpouse" class="small">{{ spouse?.firstName }} {{ spouse?.lastName }}</p>
      <p *ngIf="!showSpouse" class="small">Non renseigné</p>
    </div>
    <dougs-natural-person-spouse-form
      *ngIf="showSpouseForm"
      [partner]="partner"
      [naturalPersonFields]="naturalPersonFields"
      (closeForm)="handleSpouseForm()"
    ></dougs-natural-person-spouse-form>
  </div>
  <div class="spouse-container__chevron" [ngClass]="{ active: showSpouseForm }">
    <i
      class="fal"
      [ngClass]="{ 'fa-chevron-down': !showSpouseForm, 'fa-chevron-up': showSpouseForm }"
      (click)="handleSpouseForm($event)"
    ></i>
  </div>
</div>
<dougs-divider></dougs-divider>
