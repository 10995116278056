<div [formGroup]="formPartnerService.formGroup" *ngIf="roleFields">
  <div *ngIf="roleFields['metadata.employee.social.contractType']?.isAvailable" class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractType']"
            formControlName="contractType"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.formationType']"
            formControlName="formationType"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.otherContractType']"
            formControlName="otherContractType"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractReason']"
            formControlName="contractReason"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractReasonEmployeeName']"
            formControlName="contractReasonEmployeeName"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractReasonAdditionalWorkload']"
            formControlName="contractReasonAdditionalWorkload"
          ></dougs-field>
        </div>
        <div class="mb-16 double-form-row">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractStartDate']"
            formControlName="contractStartDate"
          ></dougs-field>
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractStartHour']"
            formControlName="contractStartHour"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractEndDate']"
            formControlName="contractEndDate"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="mb-16">
    <dougs-field
      [field]="roleFields['exitDocuments']"
      formControlName="exitDocuments"
      (uploadFile)="
        partnerComponentService.uploadFiles(
          partner,
          roleFields['exitDocuments']?.input?.singularDocumentType,
          'exitDocuments',
          $event
        )
      "
      (deleteFile)="
        partnerComponentService.deleteFiles(
          partner,
          roleFields['exitDocuments']?.input?.singularDocumentType,
          'exitDocuments',
          $event,
          roleFields['exitDocuments']?.input?.allowMultiple
        )
      "
    ></dougs-field>
  </div>
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.contractByDougs']"
            formControlName="contractByDougs"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="mb-16">
    <dougs-field
      [field]="roleFields['contracts']"
      formControlName="contracts"
      (uploadFile)="
        partnerComponentService.uploadFiles(
          partner,
          roleFields['contracts']?.input?.singularDocumentType,
          'contracts',
          $event
        )
      "
      (deleteFile)="
        partnerComponentService.deleteFiles(
          partner,
          roleFields['contracts']?.input?.singularDocumentType,
          'contracts',
          $event,
          roleFields['contracts']?.input?.allowMultiple
        )
      "
    ></dougs-field>
  </div>
</div>
