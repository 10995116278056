<dougs-divider></dougs-divider>
<div class="partner-settings-card__content p-24">
  <div class="informations">
    <h5 class="partner-settings-card__content__sticky">Administration</h5>
  </div>
  <div>
    <div>
      <dougs-button (click)="deletePartner()" class="mb-8" color="delete">
        <i class="mr-8 fal fa-trash"></i>
        Supprimer la personne
      </dougs-button>
    </div>
    <div>
      <dougs-button *ngIf="showOwnerTransfer" (click)="showOwnerTransferModal()" class="mb-8" color="delete">
        <i class="mr-8 fal fa-user-crown"></i>
        Définir comme propriétaire du compte
      </dougs-button>
    </div>
    <div *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin">
      <a class="color-admin" (click)="showPartnerHistoryModal()">Voir l'historique des modifications</a>
    </div>
  </div>
</div>
