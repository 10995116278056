import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { AvatarComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { PartnerComponentService } from '../../../../services/partner-component.service';
import { PartnerPositionFieldComponent } from '../../partner-position-field/partner-position-field.component';

@Component({
  selector: 'dougs-legal-person-general',
  templateUrl: './legal-person-general.component.html',
  styleUrls: ['./legal-person-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AvatarComponent,
    ImageCropperModule,
    FieldComponent,
    PartnerPositionFieldComponent,
  ],
})
export class LegalPersonGeneralComponent {
  @Input() partner!: Partner;
  @Input() legalPersonFields!: Fields;
  @Input() partnerFields!: Fields;
  imageFile!: File | null;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly partnerComponentService: PartnerComponentService,
  ) {}

  uploadAvatar(e: Event): void {
    const fileList: FileList | null = (e.target as HTMLInputElement).files;
    const file: File | null | undefined = fileList?.item(0);
    if (fileList && file) {
      this.imageFile = file;
    }
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    if (event.blob && this.imageFile) {
      await this.formPartnerService.uploadLegalPersonAvatar(new File([event.blob], this.imageFile?.name), this.partner);
    }
  }
}
