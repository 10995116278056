import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ButtonComponent,
  ConfirmationModalComponent,
  DividerComponent,
  ModalService,
  OverlayCloseEvent,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';
import { PartnerComponentService } from '../../../services/partner-component.service';
import { OwnerTransferModalComponent } from '../../owner-transfer-modal/owner-transfer-modal.component';
import { PartnerChangesHistoryModalComponent } from '../../partner-changes-history-modal/partner-changes-history-modal.component';

@Component({
  selector: 'dougs-partner-admin',
  templateUrl: './partner-admin.component.html',
  styleUrls: ['./partner-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DividerComponent, ButtonComponent, NgIf, AsyncPipe],
})
export class PartnerAdminComponent {
  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.showOwnerTransfer =
      !!this.partner.naturalPersonId &&
      !(this.partner.isClient || this.partner.isVendor) &&
      this.userStateService.activeUser.profileId !== this.partner.naturalPersonId;
  }

  get partner(): Partner {
    return this._partner;
  }

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  public showOwnerTransfer = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly partnerComponentService: PartnerComponentService,
    public readonly userStateService: UserStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async deletePartner(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer <b>${this.partner?.fullName}</b> ? Cette action est <b>définitive</b>.`,
            yesText: 'Supprimer',
            noText: 'Annuler',
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      this.closeModal.emit();
      await this.partnerComponentService.deletePartner(this.partner);
    }
  }

  showPartnerHistoryModal(): void {
    this.modalService.open(PartnerChangesHistoryModalComponent, {
      data: {
        partner: this.partner,
      },
    });
  }

  async showOwnerTransferModal(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(OwnerTransferModalComponent, {
          data: {
            partner: this.partner,
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    if (modalResult) {
      this.cdr.markForCheck();
    }
  }
}
