import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable({
  providedIn: 'root',
})
export class LegalPersonComponentService {
  public isRefreshingCompanyFromSiren = false;

  shouldShowRefreshCompanyButton$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin ||
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  constructor(
    private readonly partnerStateService: PartnerStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly logger: LoggerService,
  ) {}

  async uploadFiles(legalPartner: Partner, fileType: string, modelKey: string, files: FileList | File): Promise<void> {
    const allowMultiple: boolean = 'length' in files;
    if (allowMultiple) {
      await this.partnerStateService.uploadLegalParterAttachments(legalPartner, files as FileList, fileType, modelKey);
    } else {
      await this.partnerStateService.uploadLegalPersonAttachment(legalPartner, files as File, fileType, modelKey);
    }
    await this.fieldsStateService.refreshPartnerFields(legalPartner);
  }

  async deleteFiles(
    legalPartner: Partner,
    fileType: string,
    modelKey: string,
    attachment: Attachment,
    allowMultiple: boolean,
  ): Promise<void> {
    await this.partnerStateService.removeLegalPersonAttachments(
      legalPartner,
      attachment,
      fileType,
      modelKey,
      allowMultiple,
    );
    await this.fieldsStateService.refreshPartnerFields(legalPartner);
  }

  async refreshLegalPartnerFromSiren(legalPartner: Partner): Promise<void> {
    try {
      this.isRefreshingCompanyFromSiren = true;
      await this.companyStateService.refreshCompanyDataFromSiren(legalPartner.legalPerson.id);
      await this.partnerStateService.getPartner(legalPartner.id, legalPartner.companyId);
      this.isRefreshingCompanyFromSiren = false;
    } catch (e) {
      this.isRefreshingCompanyFromSiren = false;
      this.logger.error(e);
    }
  }
}
