import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FlashMessagesService,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-owner-transfer-modal',
  templateUrl: './owner-transfer-modal.component.html',
  styleUrls: ['./owner-transfer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class OwnerTransferModalComponent {
  public password!: string;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      partner: Partner;
    },
    private readonly userStateService: UserStateService,
    private readonly modalRef: ModalRef,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  async setUserProfile(): Promise<void> {
    const isUserProfileUpdated: boolean = await this.userStateService.setUserProfile(
      this.data.partner.naturalPersonId,
      this.password,
    );
    if (isUserProfileUpdated) {
      this.flashMessagesService.show('Le propriétaire du compte a été modifié avec succès !', {
        type: 'success',
      });
      await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
      this.modalRef.close();
    }
  }
}
