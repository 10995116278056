<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="naturalPerson">
    <div class="partner-settings-card__content p-24">
      <div class="informations">
        <h5 class="partner-settings-card__content__sticky">Identité</h5>
      </div>
      <dougs-natural-person-contact
        [partner]="partner"
        [partnerFields]="partnerFields"
        [naturalPersonFields]="naturalPersonFields"
        [dougsDetectFormChanges]="formPartnerService.formGroupHasBeenTouched$ | async"
      ></dougs-natural-person-contact>
    </div>
    <dougs-divider *ngIf="showBirthSettings"></dougs-divider>
    <div class="partner-settings-card__content p-24" *ngIf="showBirthSettings">
      <div class="informations">
        <h5 class="partner-settings-card__content__sticky">Naissance</h5>
      </div>
      <dougs-natural-person-birth
        [partner]="partner"
        [naturalPersonFields]="naturalPersonFields"
      ></dougs-natural-person-birth>
    </div>
    <dougs-divider></dougs-divider>
    <div class="partner-settings-card__content p-24">
      <div class="informations">
        <h5 class="partner-settings-card__content__sticky">Informations</h5>
      </div>
      <dougs-natural-person-information
        [partner]="partner"
        [naturalPersonFields]="naturalPersonFields"
      ></dougs-natural-person-information>
    </div>
    <ng-container *ngIf="showFamilySettings">
      <dougs-divider></dougs-divider>
      <div class="partner-settings-card__content p-24">
        <div class="family">
          <div class="partner-settings-card__content__sticky">
            <h5 class="mb-8">Famille</h5>
            <dougs-panel-info>
              <i class="fal fa-lightbulb-on mb-8 color-primary fa-2x"></i>
              <div>
                Les informations sur vos parents et votre conjoint(e) sont indispensables pour certaines démarches
                juridiques
              </div>
            </dougs-panel-info>
          </div>
        </div>
        <div>
          <dougs-natural-person-parents
            [partner]="partner"
            [partnerFields]="partnerFields"
            *ngIf="partnerFields['metadata.parents']?.isAvailable"
          ></dougs-natural-person-parents>
          <ng-container *ngIf="naturalPersonFields['metadata.isSingle']?.isAvailable">
            <div class="mb-16" formGroupName="metadata">
              <dougs-field
                [field]="naturalPersonFields['metadata.isSingle']"
                [showLabel]="false"
                formControlName="isSingle"
              ></dougs-field>
            </div>
          </ng-container>
          <dougs-natural-person-spouse
            *ngIf="naturalPersonFields['spouse']?.isAvailable"
            [partner]="partner"
            [naturalPersonFields]="naturalPersonFields"
          ></dougs-natural-person-spouse>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
