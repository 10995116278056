<div
  id="parents-container"
  (click)="openParentsForm()"
  [ngClass]="{ active: showParentsForm }"
  class="parents-container py-16 pr-16 pl-8"
>
  <div class="parents-container__content">
    <div class="parents-icon">
      <div class="parents-icon__content">
        <i
          class="fal fa-users"
          [ngClass]="{ 'color-primary': showParentsForm, ' color-primary-700': !showParentsForm }"
        ></i>
      </div>
    </div>
    <div *ngIf="!showParentsForm">
      <h5>Parents</h5>
      <p *ngIf="showFather || showMother" class="small">
        <span *ngIf="showFather">{{ father.firstName }} {{ father.lastName }}</span>
        <span *ngIf="showFather && showMother">&nbsp;et&nbsp;</span>
        <span *ngIf="showMother">{{ mother.firstName }} {{ mother.lastName }}</span>
      </p>
      <p *ngIf="!showFather && !showMother" class="small">Non renseigné</p>
    </div>
    <dougs-natural-person-parents-form
      (closeForm)="handleParentsForm()"
      *ngIf="showParentsForm"
      [partnerFields]="partnerFields"
      [partner]="partner"
    ></dougs-natural-person-parents-form>
  </div>
  <div [ngClass]="{ active: showParentsForm }" class="parents-container__chevron">
    <i
      (click)="handleParentsForm($event)"
      [ngClass]="{ 'fa-chevron-down': !showParentsForm, 'fa-chevron-up': showParentsForm }"
      class="fal"
    ></i>
  </div>
</div>
<dougs-divider class="mb-16"></dougs-divider>
