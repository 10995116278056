import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { Partner } from '@dougs/partners/dto';

@Injectable()
export class CompletionComponentService {
  hasNotFinishedPartnerCompletion(company: Company, partner: Partner | undefined): boolean {
    return (
      !!partner &&
      !!company.completion?.models?.partners[partner.id]?.percent &&
      company.completion?.models?.partners[partner.id]?.percent < 100
    );
  }
}
