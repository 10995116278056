import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingPipe, URL } from '@dougs/core/routing';
import {
  ButtonComponent,
  CompletionComponent,
  ConfirmationModalComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  OverlayCloseEvent,
} from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { FormPartnerService } from '../../forms/form-partner.service';
import { CompletionComponentService } from '../../services/completion.component.service';
import { PartnerComponentService } from '../../services/partner-component.service';
import { LegalPersonComponent } from './legal-person/legal-person.component';
import { NaturalPersonComponent } from './natural-person/natural-person.component';
import { PartnerAdminComponent } from './partner-admin/partner-admin.component';
import { PartnerHeaderComponent } from './partner-header/partner-header.component';
import { PartnerMenuComponent } from './partner-menu/partner-menu.component';
import { PartnerRolesComponent } from './partner-roles/partner-roles.component';

@Component({
  selector: 'dougs-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormPartnerService, CompletionComponentService],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    PartnerHeaderComponent,
    PartnerMenuComponent,
    NaturalPersonComponent,
    LegalPersonComponent,
    PartnerRolesComponent,
    PartnerAdminComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
    CompletionComponent,
    RoutingPipe,
  ],
})
export class PartnerModalComponent implements OnInit, OnDestroy {
  public partner?: Partner;

  public partnerSubscription!: Subscription;

  public identitySectionActive = true;
  public rolesSectionActive = false;
  public adminSectionActive = false;

  hasInit = false;
  public containerToOpen: PartnerPosition['key'] | string | null = null;

  @ViewChild('identitySection', { read: ElementRef }) indentitySectionElement!: ElementRef;
  @ViewChild('rolesSection', { read: ElementRef }) rolesSectionElement!: ElementRef;
  @ViewChild('adminSection', { read: ElementRef }) adminSectionElement!: ElementRef;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      partnerId: number;
      companyId: number;
      isVendor: boolean;
      sectionId?: 'identitySection' | 'rolesSection' | 'adminSection';
      containerToOpen?: PartnerPosition['key'] | string;
      roleToAdd: PartnerPosition['key'];
    },
    private readonly modalRef: ModalRef,
    public readonly partnerStateService: PartnerStateService,
    public readonly fieldsStateService: FieldsStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly completionComponentService: CompletionComponentService,
    public readonly formPartnerService: FormPartnerService,
    public readonly modalService: ModalService,
    public readonly cdr: ChangeDetectorRef,
    public readonly partnerComponentService: PartnerComponentService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.partnerStateService.refreshPartners(this.data.companyId);
    await this.partnerStateService.getPartner(this.data.partnerId, this.data.companyId);
    this.partnerSubscription = this.subscribeToPartnerOrVendor(this.data.isVendor);
  }

  async closeModal(): Promise<void> {
    if (this.formPartnerService?.formGroup?.dirty) {
      const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
        this.modalService
          .open(ConfirmationModalComponent, {
            data: {
              title: 'Confirmation',
              body: 'Vous avez des modifications non sauvées. Êtes-vous sûr de vouloir continuer&nbsp;?',
              yesText: 'Oui, abandonner mes modifications',
              noText: 'Non, rester sur le formulaire',
            },
          })
          .afterClosed$.pipe(take(1)),
      );
      const closeModal = !!modalResult?.data;
      if (closeModal) {
        this.modalRef.close();
      }
    } else {
      this.modalRef.close();
    }
  }

  async submitForm(): Promise<void> {
    if (this.partner) {
      await this.formPartnerService.submitForm(this.partner);
    }
  }

  private subscribeToPartnerOrVendor(isVendor: boolean): Subscription {
    if (isVendor) {
      return this.subscribeToPartners(this.partnerStateService.vendorsSettings$);
    }
    return this.subscribeToPartners(this.partnerStateService.partners$);
  }

  private subscribeToPartners(partnerState: Observable<Partner[]>): Subscription {
    return partnerState
      .pipe(
        map(async (partners: Partner[]) => {
          this.partner = partners?.find((partner) => partner.id === this.data.partnerId);
          if (this.partner) {
            if (this.data.roleToAdd && !this.hasInit) {
              await this.partnerStateService.updatePartner({ ...this.partner, [this.data.roleToAdd]: true });
            }
            this.formPartnerService.populatePartnerForm(this.partner);
            await this.fieldsStateService.refreshPartnerFields(this.partner);
            await this.fieldsStateService.refreshPartnerRoleFields(this.partner);
          }
          this.cdr.markForCheck();
          if (!this.hasInit) {
            this.scrollToSectionAndOpenContainer();
            this.hasInit = true;
          }
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  public onScroll(): void {
    this.detectMenuSection();
  }

  private detectMenuSection(): void {
    this.rolesSectionActive = this.rolesSectionElement?.nativeElement
      ? this.isInMenuSection(this.rolesSectionElement.nativeElement)
      : false;
    this.adminSectionActive = this.adminSectionElement?.nativeElement
      ? this.isInMenuSection(this.adminSectionElement.nativeElement)
      : false;
    this.identitySectionActive = this.indentitySectionElement?.nativeElement
      ? this.isInMenuSection(this.indentitySectionElement.nativeElement) ||
        (!this.rolesSectionActive && !this.adminSectionActive)
      : false;
  }

  isInMenuSection(element: HTMLElement): boolean {
    return element.getBoundingClientRect().bottom - 150 > 0 && element.getBoundingClientRect().top - 160 < 0;
  }

  ngOnDestroy(): void {
    this.partnerSubscription?.unsubscribe();
  }

  private scrollToSectionAndOpenContainer(): void {
    if (!this.data.sectionId && !this.data.containerToOpen) {
      return;
    }

    setTimeout(() => {
      if (this.data.sectionId && this.data.sectionId !== 'identitySection') {
        this.identitySectionActive = false;

        switch (this.data.sectionId) {
          case 'adminSection':
            this.adminSectionActive = true;
            this.adminSectionElement?.nativeElement.scrollIntoView({ behavior: 'smooth' });
            break;
          case 'rolesSection':
            this.rolesSectionActive = true;
            this.rolesSectionElement?.nativeElement.scrollIntoView({ behavior: 'smooth' });
            break;
        }
      }

      if (this.data.containerToOpen) {
        this.containerToOpen = this.data.containerToOpen;
      }

      this.cdr.markForCheck();
    });
  }

  protected readonly URL = URL;
}
