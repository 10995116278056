import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { FlashMessagesService, ModalService, OverlayCloseEvent, Pill } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { COGEP_ACCOUNTING_FIRM_ID } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { AddPartnerModalComponent } from '../modals/add-partner-modal/add-partner-modal.component';
import { PartnerModalComponent } from '../modals/partner-modal/partner-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PartnerComponentService {
  private isSendingEmailConfirmation = false;

  constructor(
    private readonly userStateService: UserStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly partnerStateService: PartnerStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
  ) {}

  shouldShowAdminSection$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  shouldShowRolesSection$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => loggedInUser.accountingFirmId !== COGEP_ACCOUNTING_FIRM_ID),
  );

  async uploadFiles(partner: Partner, fileType: string, modelKey: string, files: FileList | File): Promise<void> {
    const allowMultiple: boolean = 'length' in files;

    if (allowMultiple) {
      await this.partnerStateService.uploadPartnerAttachments(partner, files as FileList, fileType, modelKey);
    } else {
      await this.partnerStateService.uploadPartnerAttachment(partner, files as File, fileType, modelKey);
    }
    await this.fieldsStateService.refreshPartnerRoleFields(partner);
  }

  async deleteFiles(
    partner: Partner,
    fileType: string,
    modelKey: string,
    attachment: Attachment,
    allowMultiple: boolean,
  ): Promise<void> {
    await this.partnerStateService.removePartnerAttachments(partner, attachment, fileType, modelKey, allowMultiple);
    await this.fieldsStateService.refreshPartnerRoleFields(partner);
  }

  public getFilteredPartners(partners: Partner[], currentFilter: string | null, showAllPartners: boolean): Partner[] {
    if (!currentFilter) {
      return partners.filter(
        (partner) =>
          partner.positions?.every((position) => !position.isActive) ||
          partner.positions?.some((position) => (showAllPartners ? true : position.isActive && !position.isEnded)),
      );
    }
    return partners.filter((partner) =>
      partner.positions?.some(
        (position) =>
          position.key === currentFilter && position.isActive && (showAllPartners ? true : !position.isEnded),
      ),
    );
  }

  public getPartnerAvailableFilters(partners: Partner[]): { key: string; label: string }[] {
    return partners.reduce((availableFilters: { key: string; label: string }[], partner) => {
      const currentAvailableFilters: { key: string; label: string }[] =
        partner.positions
          ?.filter((position) => position.isActive)
          .map((position) => ({
            key: position.key,
            label: position.label,
          })) || [];
      if (this.companyStateService.activeCompany.flags.includes('allowVendorCategoryAssociation')) {
        availableFilters = availableFilters.filter((filter) => filter.key !== 'isVendor');
      }
      return _.unionBy(availableFilters, currentAvailableFilters, 'key');
    }, []);
  }

  async updateVendorsSettings(companyId: number, firstCall: boolean): Promise<void> {
    await this.partnerStateService.refreshVendorsSettings(companyId, firstCall);
  }

  async sendEmailConfirmation(): Promise<void> {
    if (this.isSendingEmailConfirmation) {
      return;
    }

    this.isSendingEmailConfirmation = true;

    if (await this.userStateService.sendEmailConfirmation()) {
      this.flashMessagesService.show("L'email de confirmation a été envoyé avec succès.", {
        type: 'success',
      });
    }

    this.isSendingEmailConfirmation = false;
  }

  public getPartnerImageUrl(partner: Partner): string {
    if (partner.naturalPerson) {
      return (
        partner.naturalPerson.avatarUrl ||
        partner.naturalPerson.alternateAvatarUrl ||
        'https://www.gravatar.com/avatar/null?s=256&d=mm'
      );
    }
    return partner.legalPerson?.id && partner.legalPerson?.avatar?.name
      ? `/companies/${partner.legalPerson.id}/avatar?filename=${partner.legalPerson?.avatar?.name}`
      : 'https://www.gravatar.com/avatar/null?s=256&d=mm';
  }

  public getPartnerActivePositions(partner: Partner): {
    label: string;
    isEnded: boolean;
    isStarted: boolean;
    type: Pill;
    endDate: Date | string;
    startDate: Date | string;
  }[] {
    return (
      partner.positions
        ?.filter((position) => position.isActive)
        .map((position) => ({
          label: position.label,
          isEnded: position.isEnded,
          isStarted: position.isStarted,
          type: this.getPillPositionType(position),
          endDate: position.endDate,
          startDate: position.startDate,
        })) || []
    );
  }

  private getPillPositionType(position: PartnerPosition): Pill {
    if (position.labelClassModifier.includes('m-primary')) {
      return 'primary';
    }
    if (position.labelClassModifier.includes('m-primary')) {
      return 'primary';
    }
    if (position.labelClassModifier.includes('m-warning')) {
      return 'warning';
    }
    if (position.labelClassModifier.includes('m-error') || position.labelClassModifier.includes('m-alert')) {
      return 'error';
    }
    return 'secondary';
  }

  async deletePartner(partner: Partner): Promise<void> {
    await this.partnerStateService.deletePartner(partner);
  }

  openPartnerModal(partnerId: string | number, companyId: number, isVendor = false): void {
    this.modalService.open(PartnerModalComponent, {
      data: {
        partnerId,
        companyId,
        isVendor,
      },
      disableClose: true,
    });
  }

  async openAddPartnerModal(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(AddPartnerModalComponent, {
          data: {
            companyId: this.companyStateService.activeCompany.id,
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      const createdPartner: Partner = modalResult.data as Partner;
      if (createdPartner) {
        this.openPartnerModal(createdPartner.id, createdPartner.companyId);
      }
    }
  }

  async addPartner(partnerType: string, companyId: number): Promise<Partner | null> {
    return await this.partnerStateService.createPartner(partnerType, companyId);
  }
}
