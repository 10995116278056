<dougs-divider></dougs-divider>
<div class="partner-settings-card__content p-24" id="rolesMissingFields">
  <div class="informations">
    <h5 class="partner-settings-card__content__sticky">Rôles</h5>
  </div>
  <div>
    <ng-container *ngFor="let position of partnerPositions; trackBy: trackByKey">
      <dougs-partner-role-container
        [position]="position"
        [company]="company"
        [roleFields]="rolesFields[position.key]"
        [naturalPersonFields]="naturalPersonFields"
        [showForm]="activePositionKey === position.key"
        [partner]="partner"
        (updateActivePosition)="updateActivePosition($event)"
      ></dougs-partner-role-container>
      <dougs-divider></dougs-divider>
    </ng-container>
  </div>
</div>
