<div *ngIf="roleFields" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <ng-container formGroupName="workPlace">
          <div class="mb-16">
            <fieldset>
              <legend>Adresse du lieu de travail</legend>
              <dougs-field
                class="mb-16"
                [field]="roleFields['metadata.employee.social.workPlace.street']"
                formControlName="street"
              ></dougs-field>
              <dougs-field
                class="mb-16"
                [field]="roleFields['metadata.employee.social.workPlace.zipcode']"
                formControlName="zipcode"
              ></dougs-field>
              <dougs-field
                [field]="roleFields['metadata.employee.social.workPlace.city']"
                formControlName="city"
              ></dougs-field>
            </fieldset>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
