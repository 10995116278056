import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerComponentService } from '../../services/partner-component.service';

@Component({
  selector: 'dougs-add-partner-modal',
  templateUrl: './add-partner-modal.component.html',
  styleUrls: ['./add-partner-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent],
})
export class AddPartnerModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      companyId: number;
    },
    private readonly modalRef: ModalRef,
    private readonly partnerComponentService: PartnerComponentService,
  ) {}

  closeModal() {
    this.modalRef.close();
  }

  async addPartner(partnerType: string): Promise<void> {
    const createdPartner: Partner | null = await this.partnerComponentService.addPartner(
      partnerType,
      this.data.companyId,
    );
    if (createdPartner) {
      this.modalRef.close(createdPartner);
    }
  }
}
