import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, ConfirmationModalComponent, ModalService, OverlayCloseEvent, PillComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';
import { AffiliateFormComponent } from './roles-form/affiliate-form/affiliate-form.component';
import { AssociateFormComponent } from './roles-form/associate-form/associate-form.component';
import { CeoFormComponent } from './roles-form/ceo-form/ceo-form.component';
import { ClientFormComponent } from './roles-form/client-form/client-form.component';
import { DirectorFormComponent } from './roles-form/director-form/director-form.component';
import { EmployeeFormComponent } from './roles-form/employee-form/employee-form.component';
import { VendorFormComponent } from './roles-form/vendor-form/vendor-form.component';

@Component({
  selector: 'dougs-partner-role-container',
  templateUrl: './partner-role-container.component.html',
  styleUrls: ['./partner-role-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    PillComponent,
    CeoFormComponent,
    AssociateFormComponent,
    EmployeeFormComponent,
    DirectorFormComponent,
    AffiliateFormComponent,
    ClientFormComponent,
    VendorFormComponent,
    ButtonComponent,
  ],
})
export class PartnerRoleContainerComponent {
  private readonly _iconClassMapping: { [key: string]: string } = {
    isCeo: 'fa-user-tie',
    isAssociate: 'fa-handshake-alt',
  };
  private readonly _metadataKeyMapping: { [key: string]: string } = {
    isCeo: 'ceo',
    isAssociate: 'associate',
  };
  public iconClass?: string;
  public isCeo = false;
  public isAssociate = false;
  public isEmployee = false;
  public isDirector = false;
  public isAffiliate = false;
  public isClient = false;
  public isVendor = false;

  @Input() partner!: Partner;
  @Input() company!: Company;
  @Input() roleFields!: Fields;
  @Input() naturalPersonFields!: Fields;
  @Input() showForm!: boolean;

  @Output() updateActivePosition: EventEmitter<string | null> = new EventEmitter<string | null>();

  private _position!: PartnerPosition;
  @Input()
  set position(position: PartnerPosition) {
    this._position = position;
    this.iconClass = this._iconClassMapping[position.key] ?? 'fa-user';
    this.setActivePosition();
  }

  get position(): PartnerPosition {
    return this._position;
  }

  constructor(
    private readonly modalService: ModalService,
    private readonly formPartnerService: FormPartnerService,
  ) {}

  private setActivePosition(): void {
    this.isCeo = this.position?.key === 'isCeo';
    this.isAssociate = this.position?.key === 'isAssociate';
    this.isEmployee = this.position?.key === 'isEmployee';
    this.isDirector = this.position?.key === 'isDirector';
    this.isAffiliate = this.position?.key === 'isAffiliate';
    this.isClient = this.position?.key === 'isClient';
    this.isVendor = this.position?.key === 'isVendor';
  }

  shouldShowRoleCompletion(role: string) {
    const percent = this.company?.completion?.models.partners[this.partner.id]?.models?.partnerRoles?.[role]?.percent;
    return this.position.isActive && percent !== null && percent !== undefined && percent < 100;
  }

  openForm(): void {
    if (this.position.isActive) {
      this.updateActivePosition.emit(this.position.key);
    }
  }

  handleParentsForm(e?: MouseEvent) {
    e?.stopPropagation();
    const activePosition: string | null = this.showForm ? null : this.position.key;
    this.updateActivePosition.emit(activePosition);
  }

  async addRole(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir ajouter ce rôle ?`,
            yesText: 'Oui, ajouter le rôle',
            noText: 'Non',
            secondaryColor: true,
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      await this.formPartnerService.addRole(
        this.partner,
        this.position,
        this.getMetadataKeyFromPosition(this.position),
      );
      this.updateActivePosition.emit(this.position.key);
    }
  }

  private getMetadataKeyFromPosition(position: PartnerPosition): string {
    return this._metadataKeyMapping[position.key];
  }
}
