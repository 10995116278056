import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner, PartnerMetadataParent } from '@dougs/partners/dto';
import { NaturalPersonParentsFormComponent } from './natural-person-parents-form/natural-person-parents-form.component';

@Component({
  selector: 'dougs-natural-person-parents',
  templateUrl: './natural-person-parents.component.html',
  styleUrls: ['./natural-person-parents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, NaturalPersonParentsFormComponent, DividerComponent],
})
export class NaturalPersonParentsComponent {
  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.father = this.partner?.metadata?.parents?.father;
    this.mother = this.partner?.metadata?.parents?.mother;
    this.showFather = !!(this.father?.lastName || this.father?.firstName);
    this.showMother = !!(this.mother?.lastName || this.mother?.firstName);
  }

  get partner(): Partner {
    return this._partner;
  }

  @Input() partnerFields!: Fields;

  public showParentsForm = false;
  public mother?: PartnerMetadataParent | undefined;
  public father?: PartnerMetadataParent | undefined;
  public showFather = false;
  public showMother = false;

  openParentsForm() {
    this.showParentsForm = true;
  }

  handleParentsForm(e?: MouseEvent) {
    e?.stopPropagation();
    this.showParentsForm = !this.showParentsForm;
  }
}
