import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import { FormSettingsService, mustMatch } from '@dougs/core/form';
import { PartnerStateService } from '@dougs/partners/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class FormUpdateEmailService extends FormSettingsService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly partnerStateService: PartnerStateService,
  ) {
    super();
    this.initializeForm();
  }

  private initializeForm(): void {
    const usePasswordChecks: boolean = this.usePasswordChecks();
    this.formGroup = new UntypedFormGroup(
      {
        email: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        emailConfirmation: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        password: usePasswordChecks
          ? new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' })
          : new UntypedFormControl(''),
      },
      {
        validators: [mustMatch('email', 'emailConfirmation')],
      },
    );
  }

  usePasswordChecks(): boolean {
    return !this.userStateService.loggedInUser.flags.includes('canChangeClientEmail');
  }

  async submitForm(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      const user: User | null = await this.userStateService.updateUserEmail(
        this.formGroup.get('email')?.value,
        this.formGroup.get('emailConfirmation')?.value,
        this.formGroup.get('password')?.value,
      );

      if (user) {
        await this.partnerStateService.refreshPartners(this.companyStateService.activeCompany.id);
      }
    }
  }
}
