<div
  (click)="openForm()"
  [ngClass]="{ inactive: !position.isActive, opened: showForm }"
  class="roles-container py-16 pr-16 pl-8"
>
  <div class="roles-container__content">
    <div class="roles-icon">
      <div class="roles-icon__content" [ngClass]="{ inactive: !position.isActive }">
        <i class="fal" [ngClass]="iconClass"></i>
      </div>
    </div>
    <div *ngIf="!showForm">
      <h5 [ngClass]="{ inactive: !position.isActive }">{{ position.label }}</h5>
      <dougs-pill type="warning" *ngIf="shouldShowRoleCompletion(position.key)">
        Complété à {{ company.completion.models.partners[partner.id].models.partnerRoles[position.key].percent }}
        %
      </dougs-pill>
    </div>
    <ng-container *ngIf="showForm">
      <div class="roles-container__content__form">
        <h5 class="mb-16 mt-8">{{ position.label }}</h5>
        <dougs-ceo-form
          *ngIf="isCeo"
          (closeForm)="handleParentsForm()"
          [partner]="partner"
          [position]="position"
          [roleFields]="roleFields"
        ></dougs-ceo-form>
        <dougs-associate-form
          *ngIf="isAssociate"
          (closeForm)="handleParentsForm()"
          [partner]="partner"
          [position]="position"
          [roleFields]="roleFields"
        ></dougs-associate-form>
        <dougs-employee-form
          *ngIf="isEmployee"
          (closeForm)="handleParentsForm()"
          [partner]="partner"
          [position]="position"
          [naturalPersonFields]="naturalPersonFields"
          [roleFields]="roleFields"
        ></dougs-employee-form>
        <dougs-director-form
          *ngIf="isDirector"
          [partner]="partner"
          [roleFields]="roleFields"
          [position]="position"
          (closeForm)="handleParentsForm()"
        ></dougs-director-form>
        <dougs-affiliate-form
          *ngIf="isAffiliate"
          [partner]="partner"
          [roleFields]="roleFields"
          [position]="position"
          (closeForm)="handleParentsForm()"
        ></dougs-affiliate-form>
        <dougs-client-form
          *ngIf="isClient"
          [partner]="partner"
          [roleFields]="roleFields"
          [position]="position"
          (closeForm)="handleParentsForm()"
        ></dougs-client-form>
        <dougs-vendor-form
          *ngIf="isVendor"
          [partner]="partner"
          [roleFields]="roleFields"
          [position]="position"
          (closeForm)="handleParentsForm()"
        ></dougs-vendor-form>
      </div>
    </ng-container>
  </div>
  <div class="roles-container__chevron" [ngClass]="{ active: showForm }">
    <i
      *ngIf="position.isActive"
      class="fal"
      [ngClass]="{ 'fa-chevron-down': !showForm, 'fa-chevron-up': showForm }"
      (click)="handleParentsForm($event)"
    ></i>
    <dougs-button (click)="addRole()" *ngIf="!position.isActive" color="secondary">
      <i class="mr-8 color-primary fas fa-plus-circle"></i>
      Ajouter le rôle
    </dougs-button>
  </div>
</div>
