import { Injectable } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { CourtAdministrationServices } from '@dougs/core/config-back';
import { FormSettingsService, isPhone } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';
import { AddressData, AddressGouvFeatureProperties } from '@dougs/ds';
import { Fields, RoleFields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner, PartnerPosition } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';

const PARTNER_SCHEMA: (Record<string, any> | string)[] = [
  'position',
  'contracts',
  'exitDocuments',
  'cooperativeDispense',
  'accres',
  'sejournProofs',
  'workLicenceProofs',
  'minimumWeekDurationDerogations',
  'recognitionOfDisabledWorkers',
  {
    metadata: [
      {
        parents: [
          'noFather',
          'noMother',
          { father: ['firstName', 'lastName', 'maidenName'] },
          { mother: ['firstName', 'lastName', 'maidenName'] },
        ],
      },
      {
        ceo: [
          'isCompanyHost',
          'isCeoOwnerOfPremises',
          'isDgEquivalent',
          'hasAcre',
          'hasAre',
          'areEndDate',
          'endDate',
          'startDate',
          'healthInsurance',
          'isMicrosocial',
          'hasPreviousTns',
          'hasSimultaneousActivity',
          'simultaneousActivity',
          'biologistAffiliation',
          'pharmacistAffiliation',
          { previousTns: ['activity', 'dateEnd', 'zipcode', 'city', 'country'] },
        ],
      },
      { associate: ['shareCount', 'endDate'] },
      { isDirector: ['hasAcre', 'hasAre', 'areEndDate', 'startDate', 'endDate'] },
      { affiliate: ['startDate', 'endDate', 'percentThreshold'] },
      { client: ['endDate'] },
      { vendor: ['endDate'] },
      {
        employee: [
          {
            social: [
              'statusJob',
              'jobName',
              'contractType',
              'formationType',
              'otherContractType',
              'contractReason',
              'contractReasonEmployeeName',
              'contractReasonAdditionalWorkload',
              'contractStartDate',
              'contractStartHour',
              'contractEndDate',
              'contractByDougs',
              'monthlyIncome',
              'monthlyIncomeType',
              'paymentMethod',
              'cooperativeDispensed',
              'arduousnessExposition',
              'arduousness',
              'cooperativeBeneficiaries',
              'exonerationOtherValue',
              'hasAccre',
              'isRegistered',
              'exonerationOtherValue',
              'weekDuration',
              'monthDuration',
              'minimumWeekDurationExonerationReason',
              'familyPersonCount',
              { workPlace: ['street', 'zipcode', 'city'] },
              {
                monday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                tuesday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                wednesday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                thursday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                friday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                saturday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
              {
                sunday: ['morningStartAt', 'morningEndAt', 'afternoonStartAt', 'afternoonEndAt'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    naturalPerson: [
      'firstName',
      'lastName',
      'middleName',
      'thirdName',
      'fourthName',
      'email',
      'phone',
      'workPhone',
      'isMan',
      'maidenName',
      'birthDate',
      'birthCity',
      'birthZipcode',
      'birthCountry',
      'nationality',
      'identityProof',
      'socialSecurityProof',
      { address: ['street', 'zipcode', 'city', 'country', 'additionalAddress'] },
      {
        metadata: [
          'socialSecurityNumber',
          'isSingle',
          {
            tns: [
              'isSpouseCovered',
              'insurancePlan',
              'previousJobName',
              'insurancePlanOtherValue',
              'previousJobCity',
              'previousJobZipcode',
              'previousJobQuiteDate',
              'simultaneousely',
              'simultaneouselyOtherValue',
              'spouseIsCoveredByPartner',
            ],
          },
          {
            idProofInfo: ['type', 'number', 'expirationDate', 'issuanceAuthority'],
          },
        ],
      },
      {
        spouse: [
          'firstName',
          'lastName',
          'isMan',
          'birthDate',
          'birthCity',
          'birthZipcode',
          'birthCountry',
          'nationality',
          'maidenName',
          'socialSecurityNumber',
          'maritalStatus',
          'matrimonialSeparationProperty',
          'patrimonialSeparationProperty',
          'hasRenunciationClause',
          'weddingCity',
          'weddingCountry',
          'weddingDate',
          'weddingTI',
          'weddingZipcode',
          { address: ['street', 'zipcode', 'city', 'country'] },
          { contact: ['phone', 'email'] },
        ],
      },
    ],
  },
  {
    legalPerson: [
      'legalForm',
      'legalName',
      'taxRegime',
      'taxRegimeOptionLetter',
      'brandName',
      'activity',
      'website',
      'bylaws',
      'kbis',
      'siren',
      'registrationDate',
      'shareCapital',
      {
        metadata: [
          'legalRepresentativeFirstName',
          'legalRepresentativeLastName',
          'legalRepresentativeEmail',
          'legalRepresentativePhone',
          'legalRepresentativeIsMan',
          'legalRepresentativeMaidenName',
          'isDomiciliated',
          'domiciliatorId',
          'domiciliatorSiren',
          'domiciliatorLegalName',
          'country',
          'immatriculationId',
          { courtsAdministrationService: ['street', 'city', 'zipcode', 'phone', 'webSite', 'name'] },
        ],
      },
      {
        headquarter: ['street', 'city', 'zipcode', 'establishmentOwnerId', 'ape', 'apeDescription'],
      },
    ],
  },
];

// Not provided in root, only provided in partner-settings-modal
@Injectable()
export class FormPartnerService extends FormSettingsService {
  private partner!: Partner;
  public selectedCourtAdministration?: string;

  fullAddressControl: FormControl<{
    street: string | undefined;
    zipcode: string | undefined;
    city: string | undefined;
  }> = new FormControl({ street: '', zipcode: '', city: '' }, { nonNullable: true });

  onChangeAddress$: Observable<void> = this.fullAddressControl.valueChanges.pipe(
    distinctUntilChanged(),
    map((fullAddress: AddressData) => {
      this.onChangeAddress(fullAddress);
    }),
  );

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly partnerStateService: PartnerStateService,
  ) {
    super(PARTNER_SCHEMA);
    this.naturalPersonSpouseFormGroup.get('contact')?.get('phone')?.setValidators(isPhone(false));
  }

  get naturalPersonFormGroup(): UntypedFormGroup {
    return this.formGroup.get('naturalPerson') as UntypedFormGroup;
  }

  get addressFormGroup(): UntypedFormGroup {
    return this.naturalPersonFormGroup.get('address') as UntypedFormGroup;
  }

  get naturalPersonMetadataFormGroup(): UntypedFormGroup {
    return this.naturalPersonFormGroup.get('metadata') as UntypedFormGroup;
  }

  get naturalPersonSpouseFormGroup(): UntypedFormGroup {
    return this.naturalPersonFormGroup.get('spouse') as UntypedFormGroup;
  }

  get naturalPersonSpouseAddressFormGroup(): UntypedFormGroup {
    return this.naturalPersonSpouseFormGroup.get('address') as UntypedFormGroup;
  }

  get naturalPersonSpouseContactFormGroup(): UntypedFormGroup {
    return this.naturalPersonSpouseFormGroup.get('contact') as UntypedFormGroup;
  }

  get tnsFormGroup(): UntypedFormGroup {
    return this.naturalPersonMetadataFormGroup.get('tns') as UntypedFormGroup;
  }

  get idProofInfoFormGroup(): UntypedFormGroup {
    return this.naturalPersonMetadataFormGroup.get('idProofInfo') as UntypedFormGroup;
  }

  get metadataFormGroup(): UntypedFormGroup {
    return this.formGroup.get('metadata') as UntypedFormGroup;
  }

  get metadataCEOFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('ceo') as UntypedFormGroup;
  }

  get metadataCEOPreviousTnsFormGroup(): UntypedFormGroup {
    return this.metadataCEOFormGroup.get('previousTns') as UntypedFormGroup;
  }

  get metadataAssociateFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('associate') as UntypedFormGroup;
  }

  get metadataDirectorFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('isDirector') as UntypedFormGroup;
  }

  get metadataAffiliateFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('affiliate') as UntypedFormGroup;
  }

  get metadataClientFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('client') as UntypedFormGroup;
  }

  get metadataVendorFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('vendor') as UntypedFormGroup;
  }

  get metadataEmployeeSocialFormGroup(): UntypedFormGroup {
    return (this.metadataFormGroup.get('employee') as UntypedFormGroup)?.get('social') as UntypedFormGroup;
  }

  get metadataParentsFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('parents') as UntypedFormGroup;
  }

  get legalPersonFormGroup(): UntypedFormGroup {
    return this.formGroup.get('legalPerson') as UntypedFormGroup;
  }

  get legalPersonMetadataFormGroup(): UntypedFormGroup {
    return this.legalPersonFormGroup.get('metadata') as UntypedFormGroup;
  }

  get legalPersonHeadquarterFormGroup(): UntypedFormGroup {
    return this.legalPersonFormGroup.get('headquarter') as UntypedFormGroup;
  }

  get legalPersonMetadataCourtAdministrationFormGroup(): UntypedFormGroup {
    return this.legalPersonMetadataFormGroup.get('courtsAdministrationService') as UntypedFormGroup;
  }

  public formatNaturalPersonForm(naturalPersonFields: Fields): void {
    this.formatFieldsForm(naturalPersonFields, this.naturalPersonFormGroup);
  }

  public formatLegalPersonForm(legalPersonFields: Fields): void {
    this.formatFieldsForm(legalPersonFields, this.legalPersonFormGroup);
  }

  public formatPartnerForm(partnerFields: Fields): void {
    this.formatFieldsForm(partnerFields);
  }

  public formatPartnerMetadataRoleForm(rolesFields: RoleFields): void {
    this.formatFieldsForm(rolesFields['isCeo']);
    this.formatFieldsForm(rolesFields['isAssociate']);
    this.formatFieldsForm(rolesFields['isDirector']);
    this.formatFieldsForm(rolesFields['isAffiliate']);
    this.formatFieldsForm(rolesFields['isClient']);
    this.formatFieldsForm(rolesFields['isVendor']);
    this.formatFieldsForm(rolesFields['isEmployee']);
  }

  public populatePartnerForm(partner: Partner): void {
    this.populateFieldsForm(partner);
    this.fullAddressControl?.setValue(
      {
        street: partner.naturalPerson?.address?.street,
        zipcode: partner.naturalPerson?.address?.zipcode,
        city: partner.naturalPerson?.address?.city,
      },
      { emitEvent: false },
    );
    this.partner = partner;
    this.listenFormGroupChanges();
  }

  public resetTnsForm(partner: Partner): void {
    this.tnsFormGroup.markAsPristine();
    this.populateFieldsForm(partner);
  }

  public resetMetadataParentsForm(partner: Partner): void {
    this.metadataParentsFormGroup.markAsPristine();
    this.populatePartnerForm(partner);
  }

  public resetMetadataRolesForm(partner: Partner, roleFormGroup: UntypedFormGroup): void {
    roleFormGroup.markAsPristine();
    this.populatePartnerForm(partner);
  }

  public resetNaturalPersonSpouseForm(partner: Partner): void {
    this.naturalPersonSpouseFormGroup.markAsPristine();
    this.naturalPersonSpouseAddressFormGroup.markAsPristine();
    this.naturalPersonSpouseContactFormGroup.markAsPristine();
    this.populatePartnerForm(partner);
  }

  public listenFormGroupChanges(): void {
    this.listenOnFormGroupChanges((values) =>
      from(
        this.fieldsStateService.refreshPartnerFields({
          ...this.partner,
          naturalPerson: mergeObjects(this.partner.naturalPerson, values.naturalPerson),
          legalPerson: mergeObjects(this.partner.legalPerson, values.legalPerson),
        }),
      ).pipe(
        concatMap(() =>
          from(
            this.fieldsStateService.refreshPartnerRoleFields({
              ...this.partner,
              metadata: mergeObjects(this.partner.metadata, values.metadata),
            }),
          ),
        ),
      ),
    );
  }

  onChangeAddress(address: AddressData): void {
    this.addressFormGroup.patchValue({
      street: address.street,
      city: address.city,
      zipcode: address.zipcode,
      additionalAddress: address.additionalAddress ?? '',
      country: address.country?.label ?? 'France',
    });
    this.addressFormGroup.get('street')?.markAsDirty();
    this.addressFormGroup.get('city')?.markAsDirty();
    this.addressFormGroup.get('zipcode')?.markAsDirty();
    this.addressFormGroup.get('country')?.markAsDirty();
    this.addressFormGroup.get('additionalAddress')?.markAsDirty();
  }

  onSelectBirthAddress(address: AddressGouvFeatureProperties): void {
    this.naturalPersonFormGroup.patchValue({
      birthZipcode: address.postcode,
      birthCity: address.city,
      birthCountry: 'fr',
    });

    this.naturalPersonFormGroup.get('birthZipcode')?.markAsDirty();
    this.naturalPersonFormGroup.get('birthCity')?.markAsDirty();
    this.naturalPersonFormGroup.get('birthCountry')?.markAsDirty();
  }

  onManualBirthAddressEntry(address: AddressData): void {
    this.naturalPersonFormGroup.patchValue({
      birthZipcode: address.zipcode,
      birthCity: address.city,
      birthCountry: address.country?.value,
    });
    this.naturalPersonFormGroup.get('birthZipcode')?.markAsDirty();
    this.naturalPersonFormGroup.get('birthCity')?.markAsDirty();
    this.naturalPersonFormGroup.get('birthCountry')?.markAsDirty();
  }

  onSelectHeadquarterAddress(address: AddressGouvFeatureProperties): void {
    this.legalPersonHeadquarterFormGroup.patchValue({
      street: address.name,
      city: address.city,
      zipcode: address.postcode,
    });

    this.legalPersonHeadquarterFormGroup.get('street')?.markAsDirty();
    this.legalPersonHeadquarterFormGroup.get('city')?.markAsDirty();
    this.legalPersonHeadquarterFormGroup.get('zipcode')?.markAsDirty();
  }

  onSelectSpouseAddress(address: AddressGouvFeatureProperties): void {
    this.naturalPersonSpouseAddressFormGroup.patchValue({
      street: address.name,
      city: address.city,
      zipcode: address.postcode,
      country: 'France',
    });

    this.naturalPersonSpouseAddressFormGroup.get('street')?.markAsDirty();
    this.naturalPersonSpouseAddressFormGroup.get('city')?.markAsDirty();
    this.naturalPersonSpouseAddressFormGroup.get('zipcode')?.markAsDirty();
    this.naturalPersonSpouseAddressFormGroup.get('country')?.markAsDirty();
  }

  onSelectSpouseBirthAddress(address: AddressGouvFeatureProperties): void {
    this.naturalPersonSpouseFormGroup.patchValue({
      birthZipcode: address.postcode,
      birthCity: address.city,
      birthCountry: 'fr',
    });

    this.naturalPersonSpouseFormGroup.get('birthZipcode')?.markAsDirty();
    this.naturalPersonSpouseFormGroup.get('birthCity')?.markAsDirty();
    this.naturalPersonSpouseFormGroup.get('birthCountry')?.markAsDirty();
  }

  async deleteTns(partner: Partner): Promise<void> {
    const updatedPartner: Partner = mergeObjects(partner, { naturalPerson: { metadata: { tns: null } } });
    await this.partnerStateService.updatePartner(updatedPartner);
  }

  async deleteParents(partner: Partner): Promise<void> {
    const updatedPartner: Partner = mergeObjects(partner, { metadata: { parents: null } });
    await this.partnerStateService.updatePartner(updatedPartner);
  }

  async deleteSpouse(partner: Partner): Promise<void> {
    const updatedPartner: Partner = mergeObjects(partner, { naturalPerson: { spouse: null } });
    await this.partnerStateService.updatePartner(updatedPartner);
  }

  async deleteRole(partner: Partner, position: PartnerPosition, metadataKey: string): Promise<void> {
    const updatedPartner: Partner = mergeObjects(partner, { [position.key]: false, metadata: { [metadataKey]: null } });
    await this.partnerStateService.updatePartner(updatedPartner);
  }

  async addRole(partner: Partner, position: PartnerPosition, metadataKey: string): Promise<void> {
    const updatedPartner: Partner = mergeObjects(partner, { [position.key]: true, metadata: { [metadataKey]: {} } });
    await this.partnerStateService.updatePartner(updatedPartner);
  }

  async uploadAvatar(file: File, partner: Partner): Promise<void> {
    if (file && (await this.partnerStateService.uploadAvatar(partner, file))) {
      await this.partnerStateService.getPartner(partner.id, partner.companyId);
    }
  }

  async uploadLegalPersonAvatar(file: File, legalPartner: Partner): Promise<void> {
    if (file && (await this.partnerStateService.uploadLegalPersonAvatar(legalPartner, file))) {
      await this.partnerStateService.getPartner(legalPartner.id, legalPartner.companyId);
    }
  }

  async submitForm(partner: Partner): Promise<boolean> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      await this.partnerStateService.updatePartner(mergeObjects(partner, this.formGroup.value));
    }
    return isFormValid;
  }

  public updateCourtAdministrationService(associatedCourtAdministration: CourtAdministrationServices): void {
    this.legalPersonMetadataCourtAdministrationFormGroup?.setValue({
      city: associatedCourtAdministration?.city ?? null,
      street: associatedCourtAdministration?.street ?? null,
      zipcode: associatedCourtAdministration?.zipcode ?? null,
      webSite: associatedCourtAdministration?.webSite ?? null,
      phone: associatedCourtAdministration?.phone ?? null,
      name: associatedCourtAdministration?.name ?? null,
    });
    this.selectedCourtAdministration = associatedCourtAdministration?.name ?? null;
    this.legalPersonMetadataCourtAdministrationFormGroup?.markAsDirty();
  }

  public setDefaultValuesToCustomFields(legalPartner: Partner): void {
    this.selectedCourtAdministration = legalPartner?.legalPerson?.metadata?.courtsAdministrationService?.name;
  }
}
