<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="legalPerson">
    <div
      class="form-layout__two-columns mb-16"
      formGroupName="metadata"
      *ngIf="legalPersonFields['metadata.legalRepresentativeFirstName']?.isAvailable"
    >
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativeFirstName']"
        formControlName="legalRepresentativeFirstName"
      ></dougs-field>
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativeLastName']"
        formControlName="legalRepresentativeLastName"
      ></dougs-field>
    </div>
    <div class="mb-16" formGroupName="metadata">
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativeIsMan']"
        formControlName="legalRepresentativeIsMan"
      ></dougs-field>
    </div>
    <div class="mb-16" formGroupName="metadata">
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativeMaidenName']"
        formControlName="legalRepresentativeMaidenName"
      ></dougs-field>
    </div>
    <div class="mb-16" formGroupName="metadata">
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativeEmail']"
        formControlName="legalRepresentativeEmail"
      ></dougs-field>
    </div>
    <div class="mb-16" formGroupName="metadata">
      <dougs-field
        [field]="legalPersonFields['metadata.legalRepresentativePhone']"
        formControlName="legalRepresentativePhone"
      ></dougs-field>
    </div>
  </ng-container>
</div>
