import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';

@Injectable()
export class VendorsComponentService {
  public MAX_VENDOR_IN_LIST = 4;
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  showExpandedVendorList = false;
  private readonly showExpandedVendorListSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.showExpandedVendorList,
  );
  showExpandedVendorList$: Observable<boolean> = this.showExpandedVendorListSubject.asObservable();

  private readonly refreshVendorsSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  refreshVendors$: Observable<void> = this.refreshVendorsSubject.asObservable().pipe(
    tap(() => this.isLoading.next(true)),
    concatMap((getShortList: boolean) =>
      this.partnerStateService.refreshVendorsSettings(this.companyStateService.activeCompany.id, getShortList),
    ),
    tap(() => this.isLoading.next(false)),
  );

  private readonly filteredVendorsSubject: BehaviorSubject<Partner[]> = new BehaviorSubject<Partner[]>([]);
  filteredVendors$: Observable<Partner[]> = this.filteredVendorsSubject.asObservable();

  private readonly filteredVendorsSlicedSubject: Subject<boolean> = new Subject<boolean>();
  filteredVendorsSliced$: Observable<boolean> = this.filteredVendorsSlicedSubject.asObservable();

  private readonly showExpendedVendorListButtonSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  showExpendedVendorListButton$: Observable<boolean> = this.showExpendedVendorListButtonSubject.asObservable();

  constructor(
    private readonly partnerStateService: PartnerStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly window: Window,
  ) {}

  shouldShowExpandedVendorListButton(vendors: Partner[]): void {
    const shouldShowExpandedVendorListButton: boolean = vendors.length > this.MAX_VENDOR_IN_LIST;
    this.showExpendedVendorListButtonSubject.next(shouldShowExpandedVendorListButton);
  }

  onShowExpendedVendorListChange(vendorsContainer?: HTMLElement): void {
    this.showExpandedVendorList = !this.showExpandedVendorList;
    this.showExpandedVendorListSubject.next(this.showExpandedVendorList);
    this.refreshVendorsSubject.next(!this.showExpandedVendorList);
    if (!this.showExpandedVendorList && vendorsContainer) {
      const partnersContainerRect: DOMRect = vendorsContainer.getBoundingClientRect();
      const top: number = partnersContainerRect.top + this.window.scrollY - 60;
      this.window.scrollTo({ top });
    }
  }

  updateFilteredVendors(vendors: Partner[]): void {
    this.shouldShowExpandedVendorListButton(vendors);
    const filteredVendorsSliced: boolean = !this.showExpandedVendorList && vendors.length > this.MAX_VENDOR_IN_LIST;
    this.filteredVendorsSlicedSubject.next(filteredVendorsSliced);
    const filteredVendors: Partner[] = this.showExpandedVendorList
      ? vendors
      : vendors.slice(0, this.MAX_VENDOR_IN_LIST);
    this.filteredVendorsSubject.next(filteredVendors);
  }
}
