<div class="modal-container">
  <div dougsModalTitle>
    <h6>Modification du compte</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <div class="card p-24 mb-24">
      Vous êtes sur le point de définir
      <b>{{ data.partner.fullName }}</b>
      comme propriétaire du compte. L'email de connexion va être remplacé par
      <b>{{ data.partner.naturalPerson.email }}</b>
      et vous allez recevoir un email à cette adresse pour modifier le mot de passe.
    </div>
    <div class="input-container">
      <dougs-form-field>
        <label dougsFormFieldLabel>Mot de passe</label>
        <input type="password" dougsFormFieldControl [required]="true" [(ngModel)]="password" />
      </dougs-form-field>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button (click)="setUserProfile()" [disabled]="!password" color="warning">Transférer le compte</dougs-button>
  </div>
</div>
