<div *ngIf="roleFields" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="ceo">
      <div class="mb-16">
        <dougs-field
          [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
          [field]="roleFields['metadata.ceo.isCompanyHost']"
          formControlName="isCompanyHost"
          [flex]="true"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.isCeoOwnerOfPremises']"
          formControlName="isCeoOwnerOfPremises"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
          [field]="roleFields['metadata.ceo.isDgEquivalent']"
          formControlName="isDgEquivalent"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.ceo.hasAcre']" formControlName="hasAcre"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.ceo.hasAre']" formControlName="hasAre"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.ceo.areEndDate']" formControlName="areEndDate"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.ceo.startDate']" formControlName="startDate"></dougs-field>
      </div>
      <div class="mb-16" *ngIf="roleFields['metadata.ceo.endDate'].isAvailable">
        <dougs-field [field]="roleFields['metadata.ceo.endDate']" formControlName="endDate"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.healthInsurance']"
          formControlName="healthInsurance"
          [isAdmin]="true"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.isMicrosocial']"
          formControlName="isMicrosocial"
          [isAdmin]="true"
          [flex]="true"
        ></dougs-field>
      </div>
      <div class="my-24">
        <h6 class="mb-16 title" *ngIf="roleFields['metadata.ceo.isPreviousTns']?.isAvailable">
          Informations sur l’activité TNS antérieure
        </h6>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.ceo.hasPreviousTns']"
            formControlName="hasPreviousTns"
            [flex]="true"
          ></dougs-field>
        </div>
        <ng-container formGroupName="previousTns">
          <div class="mb-16" *ngIf="roleFields['metadata.ceo.previousTns.activity']?.isAvailable">
            <dougs-field
              [field]="roleFields['metadata.ceo.previousTns.activity']"
              formControlName="activity"
            ></dougs-field>
          </div>
          <div class="mb-16" *ngIf="roleFields['metadata.ceo.previousTns.dateEnd']?.isAvailable">
            <dougs-field
              [field]="roleFields['metadata.ceo.previousTns.dateEnd']"
              formControlName="dateEnd"
            ></dougs-field>
          </div>
          <div
            class="form-layout__two-columns mb-16"
            *ngIf="roleFields['metadata.ceo.previousTns.zipcode']?.isAvailable"
          >
            <dougs-field
              [useAddressAutocomplete]="true"
              [field]="roleFields['metadata.ceo.previousTns.zipcode']"
              formControlName="zipcode"
            ></dougs-field>
            <dougs-field
              [useAddressAutocomplete]="true"
              [field]="roleFields['metadata.ceo.previousTns.city']"
              formControlName="city"
            ></dougs-field>
          </div>
          <div class="mb-16" *ngIf="roleFields['metadata.ceo.previousTns.country']?.isAvailable">
            <dougs-field
              [field]="roleFields['metadata.ceo.previousTns.country']"
              formControlName="country"
            ></dougs-field>
          </div>
        </ng-container>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.hasSimultaneousActivity']"
          formControlName="hasSimultaneousActivity"
          [isAdmin]="true"
          [flex]="true"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.simultaneousActivity']"
          formControlName="simultaneousActivity"
          [isAdmin]="true"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.biologistAffiliation']"
          formControlName="biologistAffiliation"
          [isAdmin]="true"
          [flex]="true"
        ></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field
          [field]="roleFields['metadata.ceo.pharmacistAffiliation']"
          formControlName="pharmacistAffiliation"
          [isAdmin]="true"
          [flex]="true"
        ></dougs-field>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="partner?.isCeo">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer ce rôle ?
      <a (click)="deleteCEORole($event)" class="color-error" href="#">Supprimer</a>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button (click)="resetForm($event)" class="mr-8" color="secondary" size="small">
      {{ formPartnerService.metadataCEOFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      (click)="saveAndClose($event)"
      *ngIf="formPartnerService?.metadataCEOFormGroup?.dirty"
      color="primary"
      size="small"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
