<ng-container *ngIf="!(companyStateService.activeCompany$ | async).isCreated">
  <div class="mb-4" *ngIf="partner.naturalPerson.metadata?.idProofInfo?.checkStatus !== 'OK'">
    <dougs-button
      color="primary"
      [disabled]="disabledIdentityCheckButton || (idCheckService.idCheckLoading$ | async)"
      (click)="idCheckService.checkIdentityDocument(partner)"
    >
      <i *ngIf="idCheckService.idCheckLoading$ | async" class="fal fa-spinner-third fa-spin mx-4"></i>
      Valider ma pièce d'identité
    </dougs-button>
  </div>
  <p *ngIf="idCheckService.idCheckLoading$ | async" class="mb-16 tiny">
    La vérification de vos documents est en cours.
  </p>
  <p
    *ngIf="partner.naturalPerson.metadata?.idProofInfo?.checkStatus && !(idCheckService.idCheckLoading$ | async)"
    [ngClass]="{
      'color-success': checkStatus === 'valid',
      'color-error': checkStatus === 'invalid',
      'color-primary-700': checkStatus === 'unchecked'
    }"
    class="mb-16 tiny"
  >
    <i *ngIf="checkStatus === 'valid'" class="fal fa-check-circle mx-4"></i>
    {{ message }}
  </p>
  <div
    class="mb-16 p-16 admin mt-16"
    *ngIf="
      (userStateService.loggedInUser$ | async).flags.includes('role:legal') ||
      (userStateService.loggedInUser$ | async).flags.includes('role:customerService')
    "
  >
    <ng-container [formGroup]="formGroup" *ngIf="formGroup">
      <div *ngIf="naturalPersonFields['metadata.idProofInfo.type']?.isAvailable">
        <dougs-field formControlName="type" [field]="naturalPersonFields['metadata.idProofInfo.type']"></dougs-field>
      </div>
      <div
        *ngIf="
          naturalPersonFields['metadata.idProofInfo.number']?.isAvailable ||
          naturalPersonFields['metadata.idProofInfo.expirationDate']?.isAvailable
        "
      >
        <ng-container>
          <div class="form-layout__two-columns mt-16">
            <dougs-field
              formControlName="expirationDate"
              [field]="naturalPersonFields['metadata.idProofInfo.expirationDate']"
            ></dougs-field>
            <dougs-field
              formControlName="number"
              [field]="naturalPersonFields['metadata.idProofInfo.number']"
            ></dougs-field>
          </div>
        </ng-container>
      </div>
      <div class="mt-16" *ngIf="naturalPersonFields['metadata.idProofInfo.issuanceAuthority']?.isAvailable">
        <dougs-field
          formControlName="issuanceAuthority"
          [field]="naturalPersonFields['metadata.idProofInfo.issuanceAuthority']"
        ></dougs-field>
      </div>
    </ng-container>
  </div>
</ng-container>
