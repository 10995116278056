@if (formPartnerService.onChangeAddress$ | async) {}
<div class="form" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="naturalPerson">
    <div class="form-layout__two-columns__avatar mb-16">
      <div class="avatar">
        <label for="natural-person-avatar">
          <div class="avatar__edition-background"></div>
          <dougs-avatar size="xxlarge" [image]="partnerComponentService.getPartnerImageUrl(partner)"></dougs-avatar>
          <i class="fal fa-pencil color-white"></i>
          <input id="natural-person-avatar" type="file" accept="image/*" (change)="uploadAvatar($event)" />
          <image-cropper
            [imageFile]="imageFile"
            [maintainAspectRatio]="true"
            [resizeToWidth]="300"
            [resizeToHeight]="300"
            [aspectRatio]="1"
            (imageCropped)="imageCropped($event)"
          ></image-cropper>
        </label>
      </div>
      <div class="form-layout__two-columns__avatar__name">
        <dougs-field
          formControlName="firstName"
          [field]="naturalPersonFields['firstName']"
          [canCopy]="true"
        ></dougs-field>
        <dougs-field
          formControlName="lastName"
          [field]="naturalPersonFields['lastName']"
          [canCopy]="true"
        ></dougs-field>
      </div>
    </div>
    <div class="form-layout mb-16">
      <dougs-field formControlName="middleName" [field]="naturalPersonFields['middleName']"></dougs-field>
      <dougs-field formControlName="thirdName" [field]="naturalPersonFields['thirdName']"></dougs-field>
      <dougs-field formControlName="fourthName" [field]="naturalPersonFields['fourthName']"></dougs-field>
    </div>
    <div class="grid-form align-end mb-16">
      <dougs-field formControlName="email" [field]="naturalPersonFields['email']"></dougs-field>
      <dougs-button
        *ngIf="isOwnerProfile"
        color="secondary"
        [fullWidth]="true"
        (click)="updateEmailComponentService.openUpdateEmailModal()"
      >
        Modifier
      </dougs-button>
    </div>
    <div class="mb-16" *ngIf="naturalPersonComponentService.shouldShowEmailConfirmation(partner) | async">
      <dougs-panel-info>
        Votre email n'est pas confirmé.
        <a href="#" (click)="$event.preventDefault(); partnerComponentService.sendEmailConfirmation()">
          Renvoyer l'email de confirmation&nbsp;&rarr;
        </a>
      </dougs-panel-info>
    </div>
    <div class="mb-16">
      <dougs-field formControlName="phone" [field]="naturalPersonFields['phone']" [canCopy]="true">
        <span dougsFormFieldError *ngIf="formPartnerService.naturalPersonFormGroup.get('phone')?.hasError('isPhone')">
          Le numéro de téléphone est invalide
        </span>
      </dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field formControlName="workPhone" [field]="naturalPersonFields['workPhone']" [canCopy]="true">
        <span
          dougsFormFieldError
          *ngIf="formPartnerService.naturalPersonFormGroup.get('workPhone')?.hasError('isPhone')"
        >
          Le numéro de téléphone est invalide
        </span>
      </dougs-field>
    </div>
    <ng-container
      *ngIf="
        naturalPersonFields['identityProof']?.isAvailable && !(companyStateService.activeCompany$ | async).isCreated
      "
    >
      <dougs-divider class="mb-16"></dougs-divider>
      <h5 class="mb-8">Pièce d’identité</h5>
      <p class="small">
        <span class="bold color-primary-700">Carte nationale d'identité</span>,
        <span class="bold color-primary-700">passeport</span>,
        <span class="bold color-primary-700">titre de séjour</span> ou
        <span class="bold color-primary-700">carte de résident</span>.
      </p>
      <p class="small mb-16">
        Les documents devront être importés en recto/verso (2 premières pages pour le passeport).
      </p>
    </ng-container>
    <div class="mb-16">
      <dougs-field
        formControlName="identityProof"
        (uploadFile)="
          naturalPersonComponentService.uploadFiles(
            partner,
            naturalPersonFields['identityProof'].input?.fileType,
            'identityProof',
            $event
          )
        "
        (deleteFile)="
          naturalPersonComponentService.deleteFiles(
            partner,
            naturalPersonFields['identityProof'].input?.fileType,
            'identityProof',
            $event,
            naturalPersonFields['identityProof'].input?.allowMultiple
          )
        "
        [field]="naturalPersonFields['identityProof']"
      ></dougs-field>
    </div>
    <div class="mb-16" *ngIf="(companyStateService.activeCompany$ | async).flags.includes('canCheckIdentityProof')">
      <dougs-profile-id-proof-check
        [partner]="partner"
        [naturalPersonFields]="naturalPersonFields"
        [formGroup]="formPartnerService.idProofInfoFormGroup"
      ></dougs-profile-id-proof-check>
    </div>
    <div
      class="mb-16"
      *ngIf="
        naturalPersonFields['address.street']?.isAvailable ||
        naturalPersonFields['address.city']?.isAvailable ||
        naturalPersonFields['address.zipcode']?.isAvailable
      "
    >
      <ng-container *ngIf="formPartnerService.addressFormGroup" formGroupName="address">
        <div class="mb-16">
          <fieldset>
            <legend>Adresse</legend>
            <div
              class="mb-16"
              *ngIf="
                naturalPersonFields['address.street']?.isAvailable ||
                naturalPersonFields['address.city']?.isAvailable ||
                naturalPersonFields['address.zipcode']?.isAvailable ||
                naturalPersonFields['address.additionalAddress']?.isAvailable
              "
            >
              <div class="mb-16">
                <dougs-address
                  [formControl]="formPartnerService.fullAddressControl"
                  [isAccountantOrAdmin]="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin"
                  [addressFieldsToDisplay]="[
                    AddressFieldsEnum.STREET,
                    AddressFieldsEnum.ZIPCODE,
                    AddressFieldsEnum.CITY,
                    AddressFieldsEnum.ADDITIONAL_ADDRESS,
                    AddressFieldsEnum.COUNTRY
                  ]"
                ></dougs-address>
              </div>
              <dougs-field
                formControlName="additionalAddress"
                [field]="naturalPersonFields['address.additionalAddress']"
                [canCopy]="true"
              ></dougs-field>
            </div>
          </fieldset>
        </div>
        <div class="mb-16" *ngIf="naturalPersonFields['address.country']?.isAvailable">
          <dougs-select
            maxHeight="250"
            dougsFormFieldControl
            formControlName="country"
            [searchable]="true"
            placeholder="-- Choisir un pays --"
          >
            <dougs-select-option
              *ngFor="let country of configBackService.countries$ | async; trackBy: trackByValue"
              [value]="country.label"
            >
              {{ country.label }}
            </dougs-select-option>
          </dougs-select>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <dougs-partner-position-field
    *ngIf="partnerFields"
    [partner]="partner"
    [partnerFields]="partnerFields"
  ></dougs-partner-position-field>
</div>
