import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../../../../forms/form-partner.service';
import { PartnerComponentService } from '../../../../../../../services/partner-component.service';

@Component({
  selector: 'dougs-employee-form-contracts',
  templateUrl: './employee-form-contracts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, DividerComponent, FieldComponent],
})
export class EmployeeFormContractsComponent {
  @Input() roleFields!: Fields;
  @Input() partner!: Partner;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly partnerComponentService: PartnerComponentService,
  ) {}
}
