<div class="tns-container">
  <label dougsFormFieldLabel>Déclaration TNS</label>
  <div class="mb-8">
    <dougs-button size="small" color="primary" [disabled]="showTnsForm" (click)="openTnsForm()">
      <i
        class="fal"
        [ngClass]="{ 'fa-pen': partner.naturalPerson?.metadata?.tns, 'fa-plus': !partner.naturalPerson?.metadata?.tns }"
      ></i>
      {{ partner.naturalPerson?.metadata?.tns ? 'Editer la TNS' : 'Renseigner la TNS' }}
    </dougs-button>
    <dougs-button
      size="small"
      class="ml-8"
      [disabled]="showTnsForm"
      (click)="deleteTns()"
      *ngIf="partner.naturalPerson?.metadata?.tns"
      color="delete"
    >
      <i class="fal fa-times"></i>
      Supprimer
    </dougs-button>
  </div>
  <dougs-natural-person-tns-form
    [partner]="partner"
    [naturalPersonFields]="naturalPersonFields"
    (closeTnsForm)="closeTnsForm()"
    *ngIf="showTnsForm"
  ></dougs-natural-person-tns-form>
</div>
