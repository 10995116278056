<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="naturalPerson">
    <div class="mb-16">
      <dougs-field formControlName="maidenName" [field]="naturalPersonFields['maidenName']"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field formControlName="birthDate" [field]="naturalPersonFields['birthDate']"></dougs-field>
    </div>
    <div class="form-layout__two-columns mb-16">
      <dougs-field
        [useAddressAutocomplete]="true"
        [useAddressContext]="true"
        [showAddressNotListedOption]="true"
        [addressFieldsToDisplay]="addressFieldsToDisplay"
        (manualAddressEntry)="formPartnerService.onManualBirthAddressEntry($event)"
        (selectAddress)="formPartnerService.onSelectBirthAddress($event)"
        formControlName="birthCity"
        [field]="naturalPersonFields['birthCity']"
      ></dougs-field>
      <dougs-field
        [useAddressAutocomplete]="true"
        [useAddressContext]="true"
        [showAddressNotListedOption]="true"
        [addressFieldsToDisplay]="addressFieldsToDisplay"
        (manualAddressEntry)="formPartnerService.onManualBirthAddressEntry($event)"
        (selectAddress)="formPartnerService.onSelectBirthAddress($event)"
        formControlName="birthZipcode"
        [field]="naturalPersonFields['birthZipcode']"
      ></dougs-field>
    </div>
    <div class="mb-16" *ngIf="naturalPersonFields['birthCountry']?.isAvailable">
      <div>
        <label dougsFormFieldLabel id="birthCountry-naturalPerson">Pays de naissance</label>
        <dougs-select
          maxHeight="250"
          dougsFormFieldControl
          formControlName="birthCountry"
          [searchable]="true"
          placeholder="-- Choisir un pays --"
        >
          <dougs-select-option
            *ngFor="let country of configBackService.countries$ | async; trackBy: trackByValue"
            [value]="country.value"
          >
            {{ country.label }}
          </dougs-select-option>
        </dougs-select>
      </div>
    </div>
  </ng-container>
</div>
