import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlashMessagesService } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable()
export class ProfileComponentService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
  ) {}

  shouldShowPreferencesSection$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  shouldShowProfileSection$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  shouldShowEmailConfirmation(partner: Partner): Observable<boolean> {
    return combineLatest([this.userStateService.activeUser$, this.companyServicesStateService.services$]).pipe(
      map(
        ([activeUser, services]) =>
          !!services?.features.hasNotificationEmailConfirmation &&
          partner.naturalPersonId === activeUser.profileId &&
          !activeUser.isEmailValidated,
      ),
    );
  }

  async resetPassword(): Promise<void> {
    if (await this.userStateService.resetPassword()) {
      this.flashMessagesService.show('Un email de réinitialisation de mot de passe vient de vous être envoyé', {
        type: 'success',
        timeout: 5000,
      });
    }
  }
}
