import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { FormPartnerService } from '../../../../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-employee-form-workdays',
  templateUrl: './employee-form-workdays.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FieldComponent],
})
export class EmployeeFormWorkdaysComponent {
  @Input() roleFields!: Fields;

  constructor(public readonly formPartnerService: FormPartnerService) {}
}
