import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { FormSettingsService } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';
import { Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner, PartnerPosition } from '@dougs/partners/dto';

const COMPANY_FIELDS_SCHEMA: (string | Record<string, any>)[] = [
  'proofOfCorporateMandates',
  {
    metadata: ['accountOwnerCompliance'],
  },
];

@Injectable()
export class FormProfileCompanyService extends FormSettingsService {
  public formGroupHasBeenDirty = false;
  private company!: Company;

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super(COMPANY_FIELDS_SCHEMA);
  }
  public populateForm(company: Company): void {
    this.populateFieldsForm(company);
    this.company = company;
    this.listenFormGroupChanges();
  }

  public listenFormGroupChanges(): void {
    super.listenOnFormGroupChanges((values) => {
      this.formGroupHasBeenDirty = true;
      const currentCompany: Company = mergeObjects(this.company, values);
      return from(this.fieldsStateService.refreshCompanyFields(currentCompany));
    });
  }

  public formatForm(fields: Fields): void {
    this.formatFieldsForm(fields);
  }

  public setPartnerRolesAsString(partner: Partner): string {
    return this.sortPositions(partner.positions)
      .map((role) => role.label)
      .join(' ; ');
  }

  private sortPositions(positions?: PartnerPosition[]): PartnerPosition[] {
    if (!positions?.length) {
      return [];
    }

    return positions
      .filter((position) => position.isActive && !position.isEnded)
      .sort((positionA, positionB) => {
        return positionA.label.localeCompare(positionB.label);
      });
  }

  async submitForm(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      await this.updateCompany(this.formGroup.getRawValue());
    }
  }

  async resetForm(company: Company): Promise<void> {
    this.formGroup.markAsPristine();
    this.formGroupHasBeenDirty = false;
    await this.companyStateService.refreshCompany();
    await this.fieldsStateService.refreshCompanyFields(company);
  }

  async updateCompany(values: Partial<Company>): Promise<void> {
    const updatedCompany: Company = mergeObjects(this.company, values);
    this.formGroupHasBeenDirty = false;
    const isUpdated: Company | null = await this.companyStateService.updateCompany(updatedCompany);

    if (!isUpdated) {
      await this.companyStateService.refreshCompany();
    }
  }

  async uploadMultipleFile(company: Company, fileList: FileList, key: string, type: string): Promise<void> {
    await this.companyStateService.uploadAttachments(company, fileList, key, type);
    await this.fieldsStateService.refreshCompanyFields(company);
  }

  async deleteMultipleFile(company: Company, attachment: Attachment, key: string): Promise<void> {
    await this.companyStateService.deleteAttachments(company, attachment, key, true);
    await this.fieldsStateService.refreshCompanyFields(company);
  }
}
