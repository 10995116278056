import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetectFormChangesDirective, DividerComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../forms/form-partner.service';
import { LegalPersonComponentService } from '../../../services/legal-person.component.service';
import { LegalPersonCourtAdministrationComponent } from './legal-person-court-administration/legal-person-court-administration.component';
import { LegalPersonGeneralComponent } from './legal-person-general/legal-person-general.component';
import { LegalPersonHeadquarterComponent } from './legal-person-headquarter/legal-person-headquarter.component';
import { LegalPersonLegalComponent } from './legal-person-legal/legal-person-legal.component';
import { LegalPersonRepresentativeComponent } from './legal-person-representative/legal-person-representative.component';

@Component({
  selector: 'dougs-legal-person',
  templateUrl: './legal-person.component.html',
  styleUrls: ['./legal-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LegalPersonComponentService],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LegalPersonGeneralComponent,
    DividerComponent,
    LegalPersonHeadquarterComponent,
    LegalPersonLegalComponent,
    DetectFormChangesDirective,
    LegalPersonRepresentativeComponent,
    NgIf,
    LegalPersonCourtAdministrationComponent,
    AsyncPipe,
  ],
})
export class LegalPersonComponent {
  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    if (this.partner) {
      this.formPartnerService.setDefaultValuesToCustomFields(this.partner);
    }
  }

  get partner(): Partner {
    return this._partner;
  }

  private _legalPersonFields!: Fields;
  @Input()
  set legalPersonFields(legalPersonFields: Fields) {
    this._legalPersonFields = legalPersonFields;
    if (this.legalPersonFields) {
      this.formPartnerService.formatLegalPersonForm(this.legalPersonFields);
    }
  }

  get legalPersonFields(): Fields {
    return this._legalPersonFields;
  }

  private _partnerFields!: Fields;
  @Input()
  set partnerFields(partnerFields: Fields) {
    this._partnerFields = partnerFields;
    if (this.partnerFields) {
      this.formPartnerService.formatPartnerForm(this.partnerFields);
    }
  }

  get partnerFields(): Fields {
    return this._partnerFields;
  }

  constructor(public readonly formPartnerService: FormPartnerService) {}
}
