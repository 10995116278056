<form (ngSubmit)="submitForm()" *ngIf="formPartnerService.formGroup" [formGroup]="formPartnerService.formGroup">
  <ng-container *ngIf="formPartnerService.formGroupChanges$ | async"></ng-container>
  <ng-container *ngIf="companyStateService.activeCompany$ | async as company">
    <ng-container *ngIf="partner">
      <div dougsModalTitle>
        <h6>Paramètres de {{ partner?.fullName }}</h6>
        <i class="fal fa-times" dougsModalClose></i>
      </div>
      <div #containerToScroll (scroll)="onScroll()" dougsModalContent="large" class="modal-content-no-padding px-32">
        <dougs-partner-header [company]="company" [partner]="partner"></dougs-partner-header>
        <dougs-partner-menu
          [adminSectionActive]="adminSectionActive"
          [adminSectionElement]="adminSectionElement"
          [identitySectionActive]="identitySectionActive"
          [identitySectionElement]="indentitySectionElement"
          [rolesSectionActive]="rolesSectionActive"
          [rolesSectionElement]="rolesSectionElement"
        ></dougs-partner-menu>
        <div class="pb-24">
          <dougs-completion
            *ngIf="completionComponentService.hasNotFinishedPartnerCompletion(company, partner)"
            [completionPercent]="company.completion.models?.partners[partner.id]?.percent"
            [missingFields]="fieldsStateService.partnerMissingFields$ | async"
            [smallSize]="true"
            [settingsLink]="URL.SETTINGS | createUrl | async"
            [container]="containerToScroll"
          >
            Remplissez le profil
          </dougs-completion>
          <div class="partner-settings-card">
            <dougs-natural-person
              #identitySection
              *ngIf="partner?.naturalPerson"
              [company]="company"
              [naturalPersonFields]="fieldsStateService.partnerNaturalPersonFields$ | async"
              [partnerFields]="fieldsStateService.partnerFields$ | async"
              [partner]="partner"
            ></dougs-natural-person>
            <dougs-legal-person
              #identitySection
              *ngIf="partner?.legalPerson"
              [legalPersonFields]="fieldsStateService.partnerLegalPersonFields$ | async"
              [partnerFields]="fieldsStateService.partnerFields$ | async"
              [partner]="partner"
            ></dougs-legal-person>
            <dougs-partner-roles
              #rolesSection
              *ngIf="partnerComponentService.shouldShowRolesSection$ | async"
              [company]="company"
              [naturalPersonFields]="fieldsStateService.partnerNaturalPersonFields$ | async"
              [partnerFields]="fieldsStateService.partnerFields$ | async"
              [partner]="partner"
              [rolesFields]="fieldsStateService.partnerRoleFields$ | async"
              [positionToActivate]="containerToOpen"
            ></dougs-partner-roles>
            <dougs-partner-admin
              #adminSection
              *ngIf="partnerComponentService.shouldShowAdminSection$ | async"
              (closeModal)="closeModal()"
              [partner]="partner"
            ></dougs-partner-admin>
          </div>
        </div>
      </div>
      <div dougsModalFooter>
        <dougs-button *ngIf="formPartnerService?.formGroup?.dirty" color="primary" type="submit">
          Enregistrer
        </dougs-button>
        <dougs-button (click)="closeModal()" color="secondary">Fermer</dougs-button>
      </div>
    </ng-container>
  </ng-container>
</form>
