import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  AvatarComponent,
  BlankStateComponent,
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { ChangeHistory } from '@dougs/operations/dto';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';

@Component({
  selector: 'dougs-partner-changes-history-modal',
  templateUrl: './partner-changes-history-modal.component.html',
  styleUrls: ['./partner-changes-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    NgFor,
    AvatarComponent,
    BlankStateComponent,
    ModalFooterDirective,
    ButtonComponent,
    JsonPipe,
    DougsDatePipe,
  ],
})
export class PartnerChangesHistoryModalComponent implements OnInit {
  public objectKeys = Object.keys;
  public partnerHistoryChanges!: ChangeHistory[];
  public isLoading = true;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      partner: Partner;
    },
    private readonly partnerStateService: PartnerStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.partnerHistoryChanges = await this.partnerStateService.getPartnerHistoryChanges(this.data.partner);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  public hasBeenCreated(change: ChangeHistory): boolean {
    return !Object.keys(change.previous).length && !!Object.keys(change.current).length;
  }

  public hasBeenRemoved(change: ChangeHistory): boolean {
    return !Object.keys(change.current).length && !!Object.keys(change.previous).length;
  }

  trackById(index: number, changeHistory: ChangeHistory): number {
    return changeHistory.id;
  }

  getAvatarUrl(changeHistory: ChangeHistory): string | undefined {
    return changeHistory.userId
      ? changeHistory.user?.profile?.avatarUrl || changeHistory.user?.profile?.alternateAvatarUrl
      : 'https://res.cloudinary.com/dougs/image/upload/w_256/l_text:Roboto_150:RO,co_white/v1484600002/lavanda_kfg5h7.png';
  }
}
