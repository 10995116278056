<div [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="naturalPerson">
    <div class="mb-16">
      <dougs-field [field]="naturalPersonFields['isMan']" formControlName="isMan"></dougs-field>
    </div>
    <div *ngIf="naturalPersonFields['nationality']?.isAvailable" class="mb-16">
      <div>
        <label dougsFormFieldLabel id="nationality-naturalPerson">Nationalité</label>
        <dougs-select
          [disabled]="!naturalPersonFields['nationality']?.isEditable"
          [searchable]="true"
          dougsFormFieldControl
          formControlName="nationality"
          maxHeight="250"
          placeholder="-- Choisir une nationalité --"
        >
          <dougs-select-option
            *ngFor="let nationality of configBackService.countries$ | async; trackBy: trackByValue"
            [value]="nationality.value"
          >
            {{ nationality.nationality || nationality.label }}
          </dougs-select-option>
        </dougs-select>
      </div>
    </div>
    <div
      *ngIf="naturalPersonFields['metadata.socialSecurityNumber']?.isAvailable"
      class="mb-16"
      formGroupName="metadata"
    >
      <dougs-field
        [field]="naturalPersonFields['metadata.socialSecurityNumber']"
        formControlName="socialSecurityNumber"
      ></dougs-field>
    </div>
    <dougs-panel-info *ngIf="naturalPersonFields['metadata.socialSecurityNumber']?.isAvailable" class="mb-16">
      Votre numéro de sécurité sociale est indispensable afin de permettre de réaliser votre affiliation au régime de
      sécurité sociale.
    </dougs-panel-info>
  </ng-container>
  <dougs-natural-person-tns
    *ngIf="naturalPersonFields['metadata.tns']?.isAvailable"
    [naturalPersonFields]="naturalPersonFields"
    [partner]="partner"
  ></dougs-natural-person-tns>
</div>
