<div class="partner-line mb-16 pt-24">
  <div class="partner-line__informations">
    <dougs-avatar [image]="partnerImageUrl" class="mr-16" size="xlarge"></dougs-avatar>
    <div>
      <h3>{{ partner.fullName }}</h3>
      <div *ngIf="partner?.position" class="position">{{ partner.position }}</div>
    </div>
  </div>
  <div class="partner-line__positions" *ngIf="partnerComponentService.shouldShowRolesSection$ | async">
    <dougs-pill type="warning" *ngIf="showCompletionPill">
      Complété à {{ company.completion.models.partners[partner.id].percent }}%
    </dougs-pill>
    <dougs-pill
      *ngFor="let position of partnerActivePositions"
      [dougsTooltipDisable]="position.isStarted && !position.isEnded"
      [dougsTooltip]="
        position.isEnded
          ? 'Terminé le ' + (position.endDate | date: 'dd/MM/yyyy')
          : 'Commence le ' + (position.startDate | date: 'dd/MM/yyyy')
      "
      [type]="position.type"
      class="mr-4 p-4"
    >
      <span [ngStyle]="{ 'text-decoration': position.isEnded || !position.isStarted ? 'line-through' : 'none' }">{{
        position.label
      }}</span>
    </dougs-pill>
  </div>
</div>
