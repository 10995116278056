<div [formGroup]="formPartnerService.tnsFormGroup" class="tns-form-container py-24 pr-24 pl-8">
  <div class="tns-icon">
    <div class="tns-icon__content">
      <i class="fal fa-file"></i>
    </div>
  </div>
  <div>
    <div class="title mb-16">
      <h5>Editer ma demande TNS</h5>
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.insurancePlan']?.isAvailable" class="mb-16">
      <div class="mb-8">
        <label>Quel est votre régime actuel d'assurance maladie&nbsp;:</label>
      </div>
      <dougs-radio-group [vertical]="true" formControlName="insurancePlan" appearance="classic">
        <dougs-radio [value]="'generalPlan'">Régime général</dougs-radio>
        <dougs-radio [value]="'agricultural'">Agriculture</dougs-radio>
        <dougs-radio [value]="'nonEmployeeOrAgricultural'">Non salarié ou agriculture</dougs-radio>
        <dougs-radio [value]="'other'">
          Autre
          <input
            class="ml-8"
            type="text"
            dougsFormFieldControl
            placeholder="à préciser"
            formControlName="insurancePlanOtherValue"
          />
        </dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="mb-16">
      <dougs-divider></dougs-divider>
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.previousJobName']?.isAvailable" class="mb-16">
      <label>Si vous avez exercé une activité non salariée auparavant, précisez laquelle :</label>
      <input type="text" dougsFormFieldControl formControlName="previousJobName" placeholder="Intitulé de l'activité" />
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.previousJobCity']?.isAvailable" class="mb-16">
      <div class="double-form-row">
        <div class="mr-16">
          <label>Code postal de l'activité</label>
          <input type="text" dougsFormFieldControl formControlName="previousJobZipcode" placeholder="Code postal" />
        </div>
        <div>
          <label>Ville de l'activité</label>
          <input type="text" dougsFormFieldControl formControlName="previousJobCity" placeholder="Ville" />
        </div>
      </div>
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.previousJobQuiteDate']?.isAvailable" class="mb-16">
      <dougs-form-field [noMargin]="true">
        <label dougsFormFieldLabel for="date">Date de cessation de l'activité :</label>
        <input
          type="text"
          dougsFormFieldControl
          placeholder="jj/mm/yyyy"
          formControlName="previousJobQuiteDate"
          id="date"
          [dougsDatepicker]="datepicker"
          mask="00/00/0000"
        />
        <i dougsFormFieldSuffix class="fal fa-calendar"></i>
        <dougs-datepicker #datepicker></dougs-datepicker>
      </dougs-form-field>
    </div>
    <ng-container *ngIf="naturalPersonFields['metadata.tns.simultaneousely']?.isAvailable">
      <div class="mb-16">
        <dougs-divider></dougs-divider>
      </div>
      <div class="mb-16">
        <div class="mb-8">
          <label>Resterez-vous simultanément&nbsp;:</label>
        </div>
        <dougs-radio-group [vertical]="true" formControlName="simultaneousely" appearance="classic">
          <dougs-radio [value]="'employee'">Employé</dougs-radio>
          <dougs-radio [value]="'agriculturalEmployee'">Salarié agriculture</dougs-radio>
          <dougs-radio [value]="'retirement'">Retraité / Pensionné</dougs-radio>
          <dougs-radio [value]="'other'">
            Autre
            <input
              class="ml-8"
              type="text"
              dougsFormFieldControl
              placeholder="A préciser"
              formControlName="simultaneouselyOtherValue"
            />
          </dougs-radio>
          <dougs-radio [value]="'no'">Non</dougs-radio>
        </dougs-radio-group>
      </div>
    </ng-container>
    <div class="mb-16">
      <dougs-divider></dougs-divider>
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.isSpouseCovered']?.isAvailable" class="mb-16">
      <dougs-form-field [noMargin]="true">
        <label>
          Votre conjoint est-il couvert à titre personnel par un régime obligatoire d’assurance maladie&nbsp;?
        </label>
        <dougs-checkbox
          appearance="toggle"
          yesText="Oui"
          noText="Non"
          [showYesNo]="true"
          class="self-center"
          formControlName="isSpouseCovered"
        ></dougs-checkbox>
      </dougs-form-field>
    </div>
    <div *ngIf="naturalPersonFields['metadata.tns.isSpouseCovered']?.isAvailable" class="mb-16">
      <dougs-form-field [noMargin]="true">
        <label>
          Souhaitez-vous que votre conjoint bénéficie également d'une couverture sociale TNS ? (Cela engendre des
          cotisations supplémentaires)
        </label>
        <dougs-checkbox
          appearance="toggle"
          yesText="Oui"
          noText="Non"
          [showYesNo]="true"
          class="self-center"
          formControlName="spouseIsCoveredByPartner"
        ></dougs-checkbox>
      </dougs-form-field>
    </div>
    <div class="card p-24 mb-16" *ngIf="showSpouseNote()">
      <i class="fal fa-lightbulb-on mb-8"></i>
      <div>
        <span>C’est noté ! Vous pourrez renseignez les informations de votre conjoint(e) dans la partie</span>
        <span class="bold-text">Famille</span>
      </div>
    </div>
    <div class="button-container">
      <dougs-button color="secondary mr-8" size="small" (click)="resetForm()">
        {{ formPartnerService?.tnsFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
      </dougs-button>
      <dougs-button
        *ngIf="formPartnerService?.tnsFormGroup?.dirty"
        color="primary"
        size="small"
        (click)="saveAndClose()"
      >
        Enregistrer
      </dougs-button>
    </div>
  </div>
</div>
