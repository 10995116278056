<ng-container *ngIf="vendorsComponentService.refreshVendors$ | async"></ng-container>
<div class="settings-card__content" #vendorsContainer>
  <div>
    <div class="settings-card__sticky-column-title">
      <h5>Fournisseurs de l'entreprise</h5>
      <p class="subtitle mt-4">L'ensemble des fournisseurs lié à l'entreprise.</p>
    </div>
  </div>
  <div class="vendors-container">
    <ng-container *ngIf="!(vendorsComponentService.isLoading$ | async)">
      <dougs-partner
        *ngFor="let vendor of vendorsComponentService.filteredVendors$ | async; trackBy: trackById"
        [company]="company"
        [partner]="vendor"
      ></dougs-partner>
      <div *ngIf="vendorsComponentService.showExpendedVendorListButton$ | async" class="see-more mt-8">
        <p
          class="tiny pointer"
          (click)="vendorsComponentService.onShowExpendedVendorListChange(vendorsContainerElementRef?.nativeElement)"
        >
          {{
            (vendorsComponentService.showExpandedVendorList$ | async)
              ? 'Réduire les fournisseurs'
              : 'Afficher tous les fournisseurs'
          }}
          <i
            class="fal fa-chevron-down ml-4"
            [ngClass]="{ 'fa-chevron-up': (vendorsComponentService.showExpandedVendorList$ | async) }"
          ></i>
        </p>
      </div>
      <div class="form-layout mt-16">
        <dougs-button (click)="openAddPartnerModal()" [fullWidth]="true" color="primary-light">
          <i class="mr-8 color-primary fas fa-plus-circle"></i>
          Ajouter une personne
        </dougs-button>
      </div>
    </ng-container>
    <dougs-loader *ngIf="vendorsComponentService.isLoading$ | async"></dougs-loader>
  </div>
</div>
