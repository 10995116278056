import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, PillComponent } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';
import { PartnerComponentService } from '../../../services/partner-component.service';

@Component({
  selector: 'dougs-profile-profile',
  templateUrl: './profile-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, PillComponent, ButtonComponent],
})
export class ProfileProfileComponent {
  public activeUserPartner?: Partner;
  private _partners!: Partner[];
  @Input()
  set partners(partners: Partner[]) {
    this._partners = partners;
    if (partners) {
      this.activeUserPartner = this.partners.find(
        (partner) => partner.naturalPersonId === this.userStateService.activeUser?.profileId,
      );
    }
  }

  get partners(): Partner[] {
    return this._partners;
  }

  @Input() completion!: number;

  constructor(
    public partnerComponentService: PartnerComponentService,
    public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
  ) {}
}
