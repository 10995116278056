import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigBackService } from '@dougs/core/config-back';
import { ControlFormFieldDirective, LabelFormFieldDirective, SelectComponent, SelectOptionComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { CourtAdministration, Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormPartnerService } from '../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-legal-person-court-administration',
  templateUrl: './legal-person-court-administration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    FieldComponent,
    AsyncPipe,
  ],
})
export class LegalPersonCourtAdministrationComponent {
  @Input() partner!: Partner;
  @Input() legalPersonFields!: Fields;

  constructor(
    public readonly formPartnerService: FormPartnerService,
    public readonly userStateService: UserStateService,
    public readonly configBackService: ConfigBackService,
  ) {}

  trackByName(index: number, item: CourtAdministration): string {
    return item.name;
  }
}
