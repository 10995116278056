<form
  (ngSubmit)="formProfileCompanyService.submitForm()"
  *ngIf="formProfileCompanyService.formGroup && companyFields['metadata.accountOwnerCompliance'].isAvailable"
  [formGroup]="formProfileCompanyService.formGroup"
>
  <ng-container *ngIf="formProfileCompanyService.formGroupChanges$ | async"></ng-container>
  <div class="settings-card__content admin">
    <div class="settings-card__sticky-column-title">
      <h5 class="mb-16 color-admin">Conformité du propriétaire du compte</h5>
    </div>
    <div [formGroup]="formProfileCompanyService.formGroup">
      <ng-container formGroupName="metadata">
        <div class="form-layout mb-16">
          <p class="small">
            <span class="bold">Rappel de ses rôles : </span>
            {{ activeUserPartnerRolesAsString }}
          </p>
        </div>
        <dougs-field
          formControlName="accountOwnerCompliance"
          [field]="companyFields['metadata.accountOwnerCompliance']"
        ></dougs-field>
      </ng-container>
      <ng-container>
        <div class="form-layout mt-16 mb-16">
          <dougs-field
            formControlName="proofOfCorporateMandates"
            (uploadFile)="
              formProfileCompanyService.uploadMultipleFile(
                company,
                $event,
                'proofOfCorporateMandates',
                'proofOfCorporateMandate'
              )
            "
            (deleteFile)="formProfileCompanyService.deleteMultipleFile(company, $event, 'proofOfCorporateMandates')"
            [field]="companyFields['proofOfCorporateMandates']"
          ></dougs-field>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="formProfileCompanyService.formGroupHasBeenDirty" class="settings-card__button-container py-16 px-32">
    <dougs-button (click)="formProfileCompanyService.resetForm(company)" class="mr-24" color="secondary"
      >Annuler</dougs-button
    >
    <dougs-button type="submit">Enregistrer</dougs-button>
  </div>
</form>
