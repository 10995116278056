import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, CheckboxComponent, LoaderComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner } from '@dougs/partners/dto';
import { PartnerComponentService } from '../services/partner-component.service';
import { PartnerComponent } from './partner/partner.component';

@Component({
  selector: 'dougs-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, NgFor, CheckboxComponent, FormsModule, NgIf, PartnerComponent, NgClass, LoaderComponent],
})
export class PartnersComponent {
  public MAX_PARTNER_IN_LIST = 4;
  public filteredPartnersSliced = false;
  @Input() company!: Company;

  private _partners!: Partner[];
  @Input()
  set partners(partners: Partner[]) {
    if (partners) {
      this._partners = _.orderBy(partners, 'createdAt');
      this.toggleInactivePartnersByDefault();
      this.availableFilters = this.partnerComponentService.getPartnerAvailableFilters(partners);
      this.updateFilteredPartners();
    }
  }

  get partners(): Partner[] {
    return this._partners;
  }

  private _missingFields!: Fields | null;

  @Input()
  set missingFields(missingFields: Fields | null) {
    this._missingFields = missingFields;
    this.toggleInactivePartnersByDefault();
  }

  get missingFields(): Fields | null {
    return this._missingFields;
  }

  @ViewChild('partnersContainer') partnersContainerElementRef!: ElementRef;

  public filteredPartners: Partner[] = [];
  public showInactivePartners = false;
  private inactivePartnersHasBeenChanged = false;
  public showExpandedPartnerList = false;
  public currentFilter: null | string = null;
  public availableFilters: { key: string; label: string }[] = [];
  public isPartnerLoading = false;

  constructor(
    public readonly partnerComponentService: PartnerComponentService,
    private readonly window: Window,
  ) {}

  updatePartnerFilter(filter?: string): void {
    if (this.currentFilter !== filter) {
      this.currentFilter = filter || null;
      this.updateFilteredPartners();
    }
  }

  updateFilteredPartners(): void {
    this.isPartnerLoading = true;
    const filteredPartners: Partner[] = this.partnerComponentService.getFilteredPartners(
      this.partners,
      this.currentFilter,
      this.showInactivePartners,
    );
    this.filteredPartners = this.showExpandedPartnerList
      ? filteredPartners
      : filteredPartners.slice(0, this.MAX_PARTNER_IN_LIST);
    this.filteredPartnersSliced = filteredPartners.length > this.MAX_PARTNER_IN_LIST;
    this.isPartnerLoading = false;
  }

  onShowInactivePartnersChange(): void {
    this.inactivePartnersHasBeenChanged = true;
    if (this.showInactivePartners) {
      this.showExpandedPartnerList = true;
    }
    this.updateFilteredPartners();
  }

  toggleInactivePartnersByDefault(): void {
    if (!this.partners || !this.missingFields) {
      return;
    }

    if (this.inactivePartnersHasBeenChanged) {
      return;
    }

    if (this.missingFields?.partnersMissingFields) {
      this.showInactivePartners = true;
      this.showExpandedPartnerList = true;

      this.updateFilteredPartners();
    }
  }

  trackById(index: number, partner: Partner): number {
    return partner.id;
  }

  trackByKey(index: number, item: { key: string; label: string }): string {
    return item.key;
  }

  async openAddPartnerModal(): Promise<void> {
    await this.partnerComponentService.openAddPartnerModal();
  }

  onShowExpandedPartnerListChange(): void {
    this.showExpandedPartnerList = !this.showExpandedPartnerList;
    if (!this.showExpandedPartnerList && this.showInactivePartners) {
      this.showInactivePartners = false;
    }
    this.updateFilteredPartners();
    if (!this.showExpandedPartnerList && this.partnersContainerElementRef?.nativeElement) {
      const partnersContainerRect: DOMRect = this.partnersContainerElementRef.nativeElement.getBoundingClientRect();
      const top: number = partnersContainerRect.top + this.window.scrollY - 60;
      this.window.scrollTo({ top });
    }
  }
}
