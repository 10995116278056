import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { UpdateEmailModalComponent } from '../modals/update-email-modal/update-email-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UpdateEmailComponentService {
  constructor(private readonly modalService: ModalService) {}

  openUpdateEmailModal(): void {
    this.modalService.open(UpdateEmailModalComponent);
  }
}
