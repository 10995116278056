import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { AvatarComponent, DougsDatePipe, Pill, PillComponent, TooltipDirective } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerComponentService } from '../../../services/partner-component.service';

@Component({
  selector: 'dougs-partner-header',
  templateUrl: './partner-header.component.html',
  styleUrls: ['./partner-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AvatarComponent, NgIf, PillComponent, NgFor, TooltipDirective, NgStyle, AsyncPipe, DougsDatePipe],
})
export class PartnerHeaderComponent implements OnInit {
  public partnerImageUrl!: string;
  public showCompletionPill = false;
  public partnerActivePositions!: {
    label: string;
    isEnded: boolean;
    isStarted: boolean;
    type: Pill;
    endDate: Date | string;
    startDate: Date | string;
  }[];

  constructor(public readonly partnerComponentService: PartnerComponentService) {}

  private _company!: Company;

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(company: Company) {
    this._company = company;
    this.updateShowCompletionPill();
  }

  private _partner!: Partner;

  get partner(): Partner {
    return this._partner;
  }

  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.partnerImageUrl = this.partnerComponentService.getPartnerImageUrl(partner);
    this.partnerActivePositions = this.partnerComponentService.getPartnerActivePositions(partner);
  }

  ngOnInit(): void {
    this.updateShowCompletionPill();
  }

  private updateShowCompletionPill(): void {
    if (this.company && this.partner) {
      this.showCompletionPill =
        !!this.company.completion?.models?.partners?.[this.partner.id]?.percent &&
        this.company.completion?.models?.partners?.[this.partner.id]?.percent < 100;
    }
  }
}
