<div class="settings-card__content" #partnersContainer>
  <div>
    <div class="settings-card__sticky-column-title">
      <h5>Personnes liées à l'entreprise</h5>
      <p class="subtitle mt-4">L'ensemble des personnes (associés, salariés, etc.) liées à l'entreprise.</p>
      <div class="filter-container mt-16">
        <h6 class="mb-4">Filtrer :</h6>
        <div>
          <dougs-button
            class="mt-4 mr-4"
            size="small"
            (click)="updatePartnerFilter()"
            [color]="!currentFilter ? 'primary' : 'secondary'"
          >
            Tous
          </dougs-button>
          <dougs-button
            *ngFor="let filter of availableFilters; trackBy: trackByKey"
            (click)="updatePartnerFilter(filter.key)"
            class="mt-4 mr-4"
            size="small"
            [color]="currentFilter === filter.key ? 'primary' : 'secondary'"
          >
            {{ filter.label }}
          </dougs-button>
        </div>
        <dougs-checkbox
          class="mt-16"
          [(ngModel)]="showInactivePartners"
          (ngModelChange)="onShowInactivePartnersChange()"
          appearance="toggle"
          [showYesNo]="true"
          yesText="Masquer les personnes sorties"
          noText="Afficher les personnes sorties"
        ></dougs-checkbox>
      </div>
    </div>
  </div>
  <div class="partners-container" id="partnersMissingFields">
    <ng-container *ngIf="!isPartnerLoading">
      <dougs-partner
        *ngFor="let partner of filteredPartners; trackBy: trackById"
        [company]="company"
        [partner]="partner"
      ></dougs-partner>
      <div *ngIf="filteredPartnersSliced || filteredPartners?.length > MAX_PARTNER_IN_LIST" class="see-more mt-8">
        <p class="tiny pointer" (click)="onShowExpandedPartnerListChange()">
          {{ showExpandedPartnerList ? 'Réduire les personnes' : 'Afficher toutes les personnes' }}
          <i
            class="fal ml-4"
            [ngClass]="{ 'fa-chevron-up': showExpandedPartnerList, 'fa-chevron-down': !showExpandedPartnerList }"
          ></i>
        </p>
      </div>
      <div class="form-layout mt-16">
        <dougs-button (click)="openAddPartnerModal()" [fullWidth]="true" color="primary-light">
          <i class="mr-8 color-primary fas fa-plus-circle"></i>
          Ajouter une personne
        </dougs-button>
      </div>
    </ng-container>
    <dougs-loader *ngIf="isPartnerLoading"></dougs-loader>
  </div>
</div>
