<div [formGroup]="formPartnerService.naturalPersonSpouseFormGroup">
  <h5 class="mb-24">Conjoint(e)</h5>
  <div class="double-form-row mb-16">
    <dougs-field [field]="naturalPersonFields['spouse.firstName']" formControlName="firstName"></dougs-field>
    <dougs-field [field]="naturalPersonFields['spouse.lastName']" formControlName="lastName"></dougs-field>
  </div>
  <div class="mb-16">
    <dougs-field [field]="naturalPersonFields['spouse.maidenName']" formControlName="maidenName"></dougs-field>
  </div>
  <div class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
  <div class="mb-16">
    <dougs-field [field]="naturalPersonFields['spouse.maritalStatus']" formControlName="maritalStatus"></dougs-field>
  </div>
  <div class="mb-16">
    <dougs-field
      [field]="naturalPersonFields['spouse.patrimonialSeparationProperty']"
      formControlName="patrimonialSeparationProperty"
      [vertical]="true"
    ></dougs-field>
  </div>
  <div class="mb-16">
    <dougs-field
      [field]="naturalPersonFields['spouse.matrimonialSeparationProperty']"
      formControlName="matrimonialSeparationProperty"
      [vertical]="true"
    ></dougs-field>
  </div>
  <div class="mb-16" *ngIf="naturalPersonFields['spouse.hasRenunciationClause']?.isAvailable">
    <dougs-field
      [field]="naturalPersonFields['spouse.hasRenunciationClause']"
      formControlName="hasRenunciationClause"
    ></dougs-field>
  </div>
  <dougs-panel-info class="mb-16" *ngIf="naturalPersonFields['spouse.hasRenunciationClause']?.isAvailable">
    <p class="small">
      Lorsque l'on est marié en communauté ou pacsé en indivision, le conjoint peut demander à être associé pour la
      moitié des parts souscrites avec les fonds provenant de la communauté.
    </p>
    <p class="small">Il est possible de le faire renoncer à cette qualité d'associé.</p>
    <p class="small">
      Le conjoint qui renonce à la qualité d'associé ne peut pas voter les décisions pour la société lors des
      assemblées.
    </p>
    <p class="small">Il n'a pas de droit dans la société comme peut l'avoir un associé.</p>
    <p class="small">
      Par contre, en cas de dissolution de la communauté de biens (Exemple : divorce), le conjoint aura droit à la
      valeur de la moitié des parts.
    </p>
  </dougs-panel-info>
  <div class="mb-16" [formGroup]="formPartnerService.naturalPersonSpouseContactFormGroup">
    <div class="mb-16 double-form-row">
      <dougs-field [field]="naturalPersonFields['spouse.contact.phone']" formControlName="phone"></dougs-field>
      <dougs-field [field]="naturalPersonFields['spouse.contact.email']" formControlName="email"></dougs-field>
    </div>
    <dougs-panel-info
      class="mb-16"
      *ngIf="
        naturalPersonFields['spouse.contact.email']?.isAvailable ||
        naturalPersonFields['spouse.contact.phone']?.isAvailable
      "
    >
      Compte tenu de votre situation matrimoniale, il est nécessaire de faire intervenir votre conjoint(e) pour la
      signature des statuts, par le biais d’une signature électronique, elle-même basée sur le numéro de téléphone et
      l’e-mail.
    </dougs-panel-info>
  </div>
  <div class="mb-16">
    <dougs-field
      [field]="naturalPersonFields['spouse.socialSecurityNumber']"
      formControlName="socialSecurityNumber"
    ></dougs-field>
  </div>
  <div class="mb-16">
    <dougs-field [field]="naturalPersonFields['spouse.isMan']" formControlName="isMan"></dougs-field>
  </div>
  <div class="mb-16" [formGroup]="formPartnerService.naturalPersonSpouseAddressFormGroup">
    <div class="mb-16">
      <fieldset>
        <legend>Adresse</legend>
        <div class="mb-16">
          <dougs-field
            [useAddressAutocomplete]="true"
            (selectAddress)="formPartnerService.onSelectSpouseAddress($event)"
            [field]="naturalPersonFields['spouse.address.street']"
            formControlName="street"
          ></dougs-field>
        </div>
        <div class="double-form-row">
          <dougs-field [field]="naturalPersonFields['spouse.address.zipcode']" formControlName="zipcode"></dougs-field>
          <dougs-field [field]="naturalPersonFields['spouse.address.city']" formControlName="city"></dougs-field>
        </div>
      </fieldset>
    </div>
    <div class="mb-16">
      <dougs-field [field]="naturalPersonFields['spouse.address.country']" formControlName="country"></dougs-field>
    </div>
  </div>
  <div class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
  <div class="mb-16">
    <dougs-field [field]="naturalPersonFields['spouse.birthDate']" formControlName="birthDate"></dougs-field>
  </div>
  <div class="mb-16 double-form-row">
    <dougs-field
      [useAddressAutocomplete]="true"
      [useAddressContext]="true"
      (selectAddress)="formPartnerService.onSelectSpouseBirthAddress($event)"
      [field]="naturalPersonFields['spouse.birthZipcode']"
      formControlName="birthZipcode"
    ></dougs-field>
    <dougs-field
      [useAddressAutocomplete]="true"
      [useAddressContext]="true"
      (selectAddress)="formPartnerService.onSelectSpouseBirthAddress($event)"
      [field]="naturalPersonFields['spouse.birthCity']"
      formControlName="birthCity"
    ></dougs-field>
  </div>
  <div class="mb-16" *ngIf="naturalPersonFields['spouse.birthCountry']?.isAvailable">
    <div>
      <label dougsFormFieldLabel>Pays de naissance</label>
      <dougs-select
        maxHeight="250"
        dougsFormFieldControl
        formControlName="birthCountry"
        [searchable]="true"
        [disabled]="!naturalPersonFields['spouse.birthCountry']?.isEditable"
        placeholder="-- Choisir un pays --"
      >
        <dougs-select-option
          *ngFor="let country of configBackService.countries$ | async; trackBy: trackByValue"
          [value]="country.value"
        >
          {{ country.label }}
        </dougs-select-option>
      </dougs-select>
    </div>
  </div>
  <div class="mb-16" *ngIf="naturalPersonFields['spouse.nationality']?.isAvailable">
    <div>
      <label dougsFormFieldLabel>Nationalité</label>
      <dougs-select
        dougsFormFieldControl
        formControlName="nationality"
        [searchable]="true"
        [disabled]="!naturalPersonFields['spouse.nationality']?.isEditable"
        placeholder="-- Choisir une nationalité --"
      >
        <dougs-select-option
          *ngFor="let nationality of configBackService.countries$ | async; trackBy: trackByValue"
          [value]="nationality.value"
        >
          {{ nationality.nationality || nationality.label }}
        </dougs-select-option>
      </dougs-select>
    </div>
  </div>
  <ng-container *ngIf="naturalPersonFields['spouse.weddingDate']?.isAvailable">
    <div class="mb-16">
      <dougs-field [field]="naturalPersonFields['spouse.weddingDate']" formControlName="weddingDate"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field
        [field]="naturalPersonFields['spouse.weddingZipcode']"
        formControlName="weddingZipcode"
      ></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field [field]="naturalPersonFields['spouse.weddingCity']" formControlName="weddingCity"></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field
        [field]="naturalPersonFields['spouse.weddingCountry']"
        formControlName="weddingCountry"
      ></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field [field]="naturalPersonFields['spouse.weddingTI']" formControlName="weddingTI"></dougs-field>
    </div>
  </ng-container>
  <ng-container *ngIf="partner.naturalPerson?.spouse">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer le conjoint(e) ?
      <span class="color-error pointer" (click)="deleteSpouse($event)">Supprimer</span>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button color="secondary mr-8" size="small" (click)="resetForm($event)">
      {{ formPartnerService?.naturalPersonSpouseFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      *ngIf="formPartnerService?.naturalPersonSpouseFormGroup?.dirty"
      color="primary"
      size="small"
      (click)="saveAndClose($event)"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
