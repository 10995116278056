import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../forms/form-partner.service';

@Component({
  selector: 'dougs-partner-position-field',
  templateUrl: './partner-position-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FieldComponent],
})
export class PartnerPositionFieldComponent {
  private _partnerFields!: Fields;
  @Input()
  set partnerFields(partnerFields: Fields) {
    this._partnerFields = partnerFields;
    if (this._partnerFields) {
      this.formPartnerService.formatPartnerForm(partnerFields);
    }
  }

  get partnerFields(): Fields {
    return this._partnerFields;
  }

  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    if (this.partner) {
      this.formPartnerService.populatePartnerForm(partner);
    }
  }

  get partner(): Partner {
    return this._partner;
  }

  constructor(public readonly formPartnerService: FormPartnerService) {}
}
