<div *ngIf="roleFields" [formGroup]="formPartnerService.formGroup">
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="isDirector">
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.isDirector.hasAcre']" formControlName="hasAcre"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.isDirector.hasAre']" formControlName="hasAre"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.isDirector.areEndDate']" formControlName="areEndDate"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.isDirector.startDate']" formControlName="startDate"></dougs-field>
      </div>
      <div class="mb-16">
        <dougs-field [field]="roleFields['metadata.isDirector.endDate']" formControlName="endDate"></dougs-field>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="partner?.isDirector">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer ce rôle ?
      <a class="color-error" href="#" (click)="deleteDirectorRole($event)">Supprimer</a>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button color="secondary mr-8" size="small" (click)="resetForm($event)">
      {{ formPartnerService.metadataDirectorFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      *ngIf="formPartnerService?.metadataDirectorFormGroup?.dirty"
      color="primary"
      size="small"
      (click)="saveAndClose($event)"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
