export interface IdentityDocument {
  uuid?: string;
  filesUuid?: string[];
  naturalPersonId?: number;
  provider?: 'ariadnext' | 'mock';
  providerUuid?: string;
  checkStatus?: string;
  internalStatus?: DocumentInternalStatus;
  details?: IdentityDocumentDetails;
  updatedAt?: Date;
  createdAt?: Date;
}

export enum DocumentInternalStatus {
  INITIALIZED = 'initialized',
  CHECKED = 'checked',
  ERROR_NO_FILE_CONTENT = 'error-no-file-content',
  ERROR_CHECK_FAILED = 'error-check-failed',
  ERROR_PAYLOAD_TOO_LARGE = 'error-payload-too-large',
  ERROR_UNSUPPORTED_MEDIA_TYPE = 'error-unsupported-media-type',
}

export interface IdentityDocumentDetails {
  number?: string;
  type?: string;
  expirationDate?: Date;
  issuanceDate?: Date;
  issuanceCountry?: string;
  issuanceAuthority?: string;
  lastname?: string;
  firstname?: string;
  birthDate?: Date;
  gender?: string;
  nationality?: string;
  sidesIssue?: string;
}
