import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Partner } from '@dougs/partners/dto';
import { PayslipListComponent } from './payslip-list/payslip-list.component';

@Component({
  selector: 'dougs-employee-payslips',
  templateUrl: './employee-payslips.component.html',
  styleUrls: ['./employee-payslips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PayslipListComponent],
})
export class EmployeePayslipsComponent {
  @Input() partner!: Partner;
}
