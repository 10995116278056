import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attachment } from '@dougs/core/files';
import { mergeObjects } from '@dougs/core/utils';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class NaturalPersonComponentService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly partnerStateService: PartnerStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
  ) {}

  shouldShowEmailConfirmation(partner: Partner): Observable<boolean> {
    return combineLatest([this.userStateService.activeUser$, this.companyServicesStateService.services$]).pipe(
      map(
        ([activeUser, services]) =>
          !!services?.features.hasNotificationEmailConfirmation &&
          partner.naturalPersonId === activeUser.profileId &&
          !activeUser.isEmailValidated,
      ),
    );
  }

  async uploadFiles(partner: Partner, fileType: string, modelKey: string, files: FileList | File): Promise<void> {
    const allowMultiple: boolean = 'length' in files;
    if (allowMultiple) {
      await this.partnerStateService.uploadNaturalPersonAttachments(partner, files as FileList, fileType, modelKey);
    } else {
      await this.partnerStateService.uploadNaturalPersonAttachment(partner, files as File, fileType, modelKey);
    }
    await this.fieldsStateService.refreshPartnerFields(partner);
  }

  async deleteFiles(
    partner: Partner,
    fileType: string,
    modelKey: string,
    attachment: Attachment,
    allowMultiple: boolean,
  ): Promise<void> {
    await this.partnerStateService.removeNaturalPersonAttachments(
      partner,
      attachment,
      fileType,
      modelKey,
      allowMultiple,
    );
    if (fileType === 'identityProof') {
      const updatedPartner: Partner = mergeObjects(partner, {
        naturalPerson: { metadata: { idProofInfo: null } },
      });
      await this.partnerStateService.updatePartner(updatedPartner);
    }
    await this.fieldsStateService.refreshPartnerFields(partner);
  }
}
