import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Company } from '@dougs/company/dto';
import { ReplaceActiveCompanyIdInUrlPipe } from '@dougs/core/routing';
import {
  AvatarComponent,
  DividerComponent,
  DougsDatePipe,
  PanelInfoComponent,
  Pill,
  PillComponent,
  TooltipDirective,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { UserStateService } from '@dougs/user/shared';
import { PartnerComponentService } from '../../services/partner-component.service';

@Component({
  selector: 'dougs-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AvatarComponent,
    NgIf,
    PillComponent,
    NgFor,
    TooltipDirective,
    NgStyle,
    PanelInfoComponent,
    DividerComponent,
    AsyncPipe,
    ReplaceActiveCompanyIdInUrlPipe,
    RouterLink,
    DougsDatePipe,
  ],
})
export class PartnerComponent implements OnInit {
  public partnerImageUrl!: string;
  public showCompletionPill = false;
  public partnerActivePositions!: {
    label: string;
    isEnded: boolean;
    isStarted: boolean;
    type: Pill;
    endDate: Date | string;
    startDate: Date | string;
  }[];

  constructor(
    public readonly partnerComponentService: PartnerComponentService,
    public readonly userStateService: UserStateService,
  ) {}

  private _company!: Company;

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(company: Company) {
    this._company = company;
    this.updateShowCompletionPill();
  }

  private _partner!: Partner;

  get partner(): Partner {
    return this._partner;
  }

  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.partnerImageUrl = this.partnerComponentService.getPartnerImageUrl(partner);
    this.partnerActivePositions = this.partnerComponentService.getPartnerActivePositions(partner);
  }

  ngOnInit(): void {
    this.updateShowCompletionPill();
  }

  trackByLabel(
    index: number,
    item: {
      label: string;
      isEnded: boolean;
      type: 'neutral' | 'admin' | 'red' | 'green' | 'primary' | 'warning' | 'primary-light';
    },
  ): string {
    return item.label;
  }

  private updateShowCompletionPill(): void {
    if (this.company && this.partner) {
      this.showCompletionPill =
        !!this.company.completion?.models?.partners?.[this.partner.id]?.percent &&
        this.company.completion?.models?.partners?.[this.partner.id]?.percent < 100;
    }
  }
}
