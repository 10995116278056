import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AvatarComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  DougsDatePipe,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  PanelInfoComponent,
  RadioComponent,
  RadioGroupComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { Session } from '@dougs/settings/dto';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormProfileService } from '../../forms/form-profile.service';
import { NaturalPersonComponentService } from '../../services/natural-person-component.service';
import { PartnerComponentService } from '../../services/partner-component.service';
import { ProfileComponentService } from '../../services/profile.component.service';
import { UpdateEmailComponentService } from '../../services/update-email.component.service';
import { ProfileIdProofCheckComponent } from './profile-id-proof-check/profile-id-proof-check.component';
import { ProfileOwnerComplianceComponent } from './profile-owner-compliance/profile-owner-compliance.component';
import { ProfileProfileComponent } from './profile-profile/profile-profile.component';

@Component({
  selector: 'dougs-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    AvatarComponent,
    ImageCropperModule,
    FieldComponent,
    ButtonComponent,
    PanelInfoComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    DividerComponent,
    RadioGroupComponent,
    RadioComponent,
    ProfileProfileComponent,
    AsyncPipe,
    ProfileIdProofCheckComponent,
    SelectComponent,
    SelectOptionComponent,
    DougsDatePipe,
    ProfileOwnerComplianceComponent,
  ],
  providers: [ProfileComponentService],
})
export class ProfileComponent {
  imageFile: File | undefined = undefined;

  @Input() company!: Company;
  @Input() companyFields!: Fields;

  @Input() session!: Session;

  private _activeUser!: User;
  @Input()
  set activeUser(activeUser: User) {
    if (activeUser) {
      this.formProfileService.populateForm(this.partner, activeUser);
    }
    this._activeUser = activeUser;
  }

  get activeUser(): User {
    return this._activeUser;
  }

  private _ownerNaturalPersonFields!: Fields;
  @Input()
  set ownerNaturalPersonFields(ownerNaturalPersonFields: Fields) {
    this._ownerNaturalPersonFields = ownerNaturalPersonFields;
    if (this.ownerNaturalPersonFields) {
      this.formProfileService.formatForm(this.ownerNaturalPersonFields);
    }
  }

  get ownerNaturalPersonFields(): Fields {
    return this._ownerNaturalPersonFields;
  }

  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    if (partner) {
      this.formProfileService.populateForm(partner, this.activeUser);
    }
    this._partner = partner;
  }

  get partner(): Partner {
    return this._partner;
  }

  @Input() completion!: number;

  constructor(
    public userStateService: UserStateService,
    public companyServicesStateService: CompanyServicesStateService,
    public profileComponentService: ProfileComponentService,
    public formProfileService: FormProfileService,
    public updateEmailComponentService: UpdateEmailComponentService,
    public partnerComponentService: PartnerComponentService,
    public naturalPersonComponentService: NaturalPersonComponentService,
    public partnerStateService: PartnerStateService,
    public companyStateService: CompanyStateService,
  ) {}

  uploadAvatarFile(e: Event): void {
    const fileList: FileList | null = (e.target as HTMLInputElement).files;
    const file: File | null | undefined = fileList?.item(0);
    if (fileList && file) {
      this.imageFile = file;
    }
  }
}
