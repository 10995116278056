import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { Partner } from '@dougs/partners/dto';
import { Spouse } from '@dougs/user/dto';
import { NaturalPersonSpouseFormComponent } from './natural-person-spouse-form/natural-person-spouse-form.component';

@Component({
  selector: 'dougs-natural-person-spouse',
  templateUrl: './natural-person-spouse.component.html',
  styleUrls: ['./natural-person-spouse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, NaturalPersonSpouseFormComponent, DividerComponent],
})
export class NaturalPersonSpouseComponent {
  private _partner!: Partner;
  @Input()
  set partner(partner: Partner) {
    this._partner = partner;
    this.spouse = this.partner.naturalPerson?.spouse;
    this.showSpouse = !!(this.spouse?.lastName || this.spouse?.firstName);
  }

  get partner(): Partner {
    return this._partner;
  }

  @Input() naturalPersonFields!: Fields;
  public showSpouseForm = false;
  public showSpouse = false;
  public spouse?: Spouse;

  openSpouseForm() {
    this.showSpouseForm = true;
  }

  handleSpouseForm(e?: MouseEvent) {
    e?.stopPropagation();
    this.showSpouseForm = !this.showSpouseForm;
  }
}
