import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeObjects } from '@dougs/core/utils';
import { DocumentInternalStatus, IdentityDocument, Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { IdProofInfo } from '@dougs/user/dto';

@Injectable()
export class IdCheckComponentService {
  constructor(private readonly partnerStateService: PartnerStateService) {
    this.currentDate = new Date().toISOString();
  }

  private readonly idCheckLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  idCheckLoading$: Observable<boolean> = this.idCheckLoading.asObservable();
  idProofAuthorizedTypes: string[] = ['NATIONAL_ID', 'PASSPORT', 'RESIDENCE_PERMIT'];
  currentDate: string;

  async checkIdentityDocument(partner: Partner): Promise<void> {
    this.idCheckLoading.next(true);
    const checkIdentityDocument: IdentityDocument[] | null = await this.partnerStateService.checkIdentityDocument(
      partner.naturalPersonId,
    );
    if (checkIdentityDocument && checkIdentityDocument[0].checkStatus) {
      const updatedPartner: Partner = mergeObjects(partner, {
        naturalPerson: {
          metadata: {
            idProofInfo: {
              type: checkIdentityDocument[0].details?.type,
              number: checkIdentityDocument[0].details?.number,
              expirationDate: checkIdentityDocument[0].details?.expirationDate,
              issuanceAuthority: checkIdentityDocument[0].details?.issuanceAuthority,
              issuanceCountry: checkIdentityDocument[0].details?.issuanceCountry,
              checkStatus: checkIdentityDocument[0].checkStatus,
              sidesIssue: checkIdentityDocument[0].details?.sidesIssue,
              internalStatus: checkIdentityDocument[0].internalStatus,
            },
          },
        },
      });
      await this.partnerStateService.updatePartner(updatedPartner);
    }
    this.idCheckLoading.next(false);
  }

  getDisabledIdentityCheckButton(partner: Partner): boolean {
    return (
      !partner?.naturalPerson?.identityProof?.length ||
      (partner?.naturalPerson?.metadata?.idProofInfo?.checkStatus === 'OK' &&
        partner.naturalPerson?.metadata?.idProofInfo?.issuanceCountry !== 'FRA' &&
        !partner.naturalPerson?.metadata?.idProofInfo?.isForeignIdProofChecked)
    );
  }

  generateIdProofCheckMessage(partner: Partner): { message: string; checkStatus: string } | undefined {
    const idProofInfo: IdProofInfo | undefined = partner?.naturalPerson?.metadata?.idProofInfo;
    if (
      (idProofInfo &&
        idProofInfo?.checkStatus === 'OK' &&
        idProofInfo?.type &&
        this.idProofAuthorizedTypes.includes(idProofInfo?.type) &&
        idProofInfo?.issuanceCountry === 'FRA') ||
      (idProofInfo &&
        idProofInfo?.checkStatus === 'OK' &&
        idProofInfo?.issuanceCountry !== 'FRA' &&
        idProofInfo?.isForeignIdProofChecked)
    ) {
      return { message: 'Nous avons validé vos documents.', checkStatus: 'valid' };
    } else if (
      idProofInfo &&
      (idProofInfo?.sidesIssue === 'MISSING_RECTO' || idProofInfo?.sidesIssue === 'MISSING_VERSO')
    ) {
      return {
        message: 'Merci de bien vouloir nous envoyer la face manquante de votre pièce d’identité.',
        checkStatus: 'invalid',
      };
    } else if (
      idProofInfo &&
      (idProofInfo?.sidesIssue === 'INVALID_RECTO' || idProofInfo?.sidesIssue === 'INVALID_VERSO')
    ) {
      return {
        message: 'Merci de bien vouloir nous envoyer la face invalide de votre pièce d’identité.',
        checkStatus: 'invalid',
      };
    } else if (idProofInfo && idProofInfo?.type && !this.idProofAuthorizedTypes.includes(idProofInfo.type)) {
      return {
        message:
          'Nos équipes n’acceptent pas ce type de pièce d’identité, nous vous invitons à essayer avec une carte d’identité, un passeport, un titre de séjour ou une carte de résident.',
        checkStatus: 'invalid',
      };
    } else if (idProofInfo && idProofInfo?.expirationDate && idProofInfo?.expirationDate <= this.currentDate) {
      return {
        message: 'Votre pièce d’identité est périmée. Merci de nous transmettre une pièce d’identité à jour.',
        checkStatus: 'invalid',
      };
    } else if (
      idProofInfo &&
      idProofInfo?.checkStatus === 'OK' &&
      !partner.naturalPerson?.metadata?.idProofInfo?.isForeignIdProofChecked &&
      partner.naturalPerson?.metadata?.idProofInfo?.issuanceCountry !== 'FRA'
    ) {
      return {
        message: "Nous allons vérifier votre identité. Aucune action n'est attendue de votre part.",
        checkStatus: 'unchecked',
      };
    } else if (
      idProofInfo &&
      idProofInfo?.checkStatus === 'ERROR' &&
      idProofInfo?.internalStatus === DocumentInternalStatus.ERROR_PAYLOAD_TOO_LARGE
    ) {
      return {
        message:
          "La pièce d'identité ne doit pas dépasser 4 Mo. Veuillez réduire la taille de votre fichier avant de le télécharger à nouveau.",
        checkStatus: 'invalid',
      };
    } else if (
      idProofInfo &&
      idProofInfo?.checkStatus === 'ERROR' &&
      idProofInfo?.internalStatus === DocumentInternalStatus.ERROR_UNSUPPORTED_MEDIA_TYPE
    ) {
      return {
        message: "Le document n'est pas au bon format. Veuillez intégrer votre pièce d’identité en JPG, PNG, ou PDF.",
        checkStatus: 'invalid',
      };
    }
    return {
      message: "Nous avons du mal à lire vos documents. Réessayez en vous assurant qu'ils soient nets et visibles.",
      checkStatus: 'invalid',
    };
  }
}
