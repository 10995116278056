import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { Partner } from '@dougs/partners/dto';
import { FormPartnerService } from '../../../../forms/form-partner.service';

@Component({
  selector: 'dougs-legal-person-representative',
  templateUrl: './legal-person-representative.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, FieldComponent],
})
export class LegalPersonRepresentativeComponent {
  @Input() partner!: Partner;
  @Input() legalPersonFields!: Fields;

  constructor(public readonly formPartnerService: FormPartnerService) {}
}
