import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { from } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { FormSettingsService } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';
import { ModalRef, ModalService } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

const USER_FIELD_SCHEMA: Record<string, any>[] = [
  {
    profile: [
      'firstName',
      'lastName',
      'email',
      'phone',
      'workPhone',
      'identityProof',
      {
        metadata: [
          {
            idProofInfo: ['type', 'number', 'expirationDate', 'issuanceAuthority'],
          },
        ],
      },
    ],
    metadata: ['compactAppAside', 'darkMode', 'subscribedToOperationNotifications'],
  },
];

@Injectable({
  providedIn: 'root',
})
export class FormProfileService extends FormSettingsService {
  partner!: Partner;

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly partnerStateService: PartnerStateService,
    private readonly modalService: ModalService,
  ) {
    super(USER_FIELD_SCHEMA);
  }

  get profileFormGroup(): FormGroup {
    return this.formGroup.get('profile') as FormGroup;
  }

  get profileMetadataFormGroup(): FormGroup {
    return this.profileFormGroup.get('metadata') as FormGroup;
  }

  get idProofInfoFormGroup(): FormGroup {
    return this.profileMetadataFormGroup.get('idProofInfo') as FormGroup;
  }

  public formatForm(ownerNaturalPersonFields: Fields): void {
    this.formatFieldsForm(ownerNaturalPersonFields);
  }

  public populateForm(partner: Partner, activeUser: User): void {
    if (activeUser && partner) {
      // TODO BACK MODIFICATION -> SAME INFOS ON USER.PROFILE & PARTNER.NATURALPERSON
      if (activeUser.profile) {
        const userWithPartnerProfileInfos: User = {
          ...activeUser,
          profile: {
            ...activeUser.profile,
            identityProof: partner.naturalPerson.identityProof,
          },
        };
        this.populateFieldsForm(userWithPartnerProfileInfos);
      }
    }

    if (partner) {
      this.partner = partner;

      this.listenFormGroupChanges();
    }
  }

  public listenFormGroupChanges(): void {
    this.listenOnFormGroupChanges((values) => {
      return from(
        this.fieldsStateService.refreshOwnerFields({
          ...this.partner,
          naturalPerson: mergeObjects(this.partner.naturalPerson, values.profile),
        }),
      );
    });
  }

  async imageCropped(event: ImageCroppedEvent, imageFile: File, activeUser: User): Promise<void> {
    if (event.blob && imageFile) {
      await this.uploadAvatar(new File([event.blob], imageFile.name), activeUser);
    }
  }

  async uploadAvatar(file: File, user: User): Promise<void> {
    if (await this.userStateService.uploadAvatar(user, file)) {
      await this.partnerStateService.refreshPartners(this.companyStateService.activeCompany.id);
    }
  }

  async submitForm(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      const wasInDarkMode: boolean = this.userStateService.hasDarkModeEnabled();
      await this.userStateService.updateActiveUser(
        mergeObjects(this.userStateService.activeUser, this.formGroup.value),
      );
      if (!wasInDarkMode && this.userStateService.hasDarkModeEnabled()) {
        const { LightsaberComponent } = await import('../components/profile/lightsaber/lightsaber.component');
        const lightsaberModal: ModalRef = this.modalService.open(LightsaberComponent);

        setTimeout(() => {
          lightsaberModal.close();
        }, 3100);
      }
    }
  }

  async cancelForm(partner: Partner, activeUser: User): Promise<void> {
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    await this.fieldsStateService.refreshOwnerFields(partner);
    await this.partnerStateService.refreshPartners(partner.companyId);
    await this.userStateService.refreshActiveUser(activeUser);
  }
}
